import React from 'react'
import { Helmet } from "react-helmet"
import HomeServices from '../../homeServices/HomeServices'
import CertificatesSlider from '../../certificatesSlider/CertificatesSlider'
import { Link } from 'react-router-dom'
import headimg from '../../../assets/img/Nkarrch.png'
import './Home.scss'
import Accordion from '../../FAQs/Accordion'
import { blogData } from '../../data'
import { homeAboutText } from '../../data'

function Home() {

    return (
        <>
            <Helmet>
                <title>Janoyan Dental</title>
                <link rel="canonical" href="http://janoyandental.am" />
                <meta name="description" content="Janoyan Dental" />
            </Helmet>
            <div className="head_section">
                <div className="head_cont">
                    <div className="head_text">
                        <h1 className="text">
                            Ձեր կյանքի փայլը սկսում է Ձեր առողջ ժպիտից
                        </h1>
                        <span className="button_btn">
                            <Link to={{ pathname: "/services" }} target="_blank">Տեսնել ավելին </Link>
                        </span>
                    </div>
                    <div className="head_img"><img src={headimg} alt="" /></div>
                </div>
            </div>
            <div className="home_about">
                {/* <div className="sec_title">
                    <h6 className="container">Մեր մասին</h6>
                </div> */}
                <div className="home_about_content container">
                    {homeAboutText.map(el => (
                        <p
                            key={el.id} >
                            {el}
                        </p>
                    ))}
                </div>

            </div>

            <HomeServices />
            <CertificatesSlider />
            <Accordion />

            {/* blog */}
            <div className="homeblog">
                <div className="sec_title">
                    <h6 className="container">Բլոգ</h6>
                </div>
                <div className="blogelements container">
                    {blogData.slice(0, 3).map(el => (
                        <div key={el.id} className="homeblog_singlepost">
                            <Link to={{
                                pathname: `/blog/${el.id}`,
                                state: {
                                    id: el.id,
                                    title: el.cont.title
                                }
                            }} className="homeblog_post">
                                <div className="homeblog_img">
                                    <img src={el.cont.img} alt="Blog-Img" />
                                </div>
                                <h3 className="homeblog_title">{el.cont.title}</h3>
                                <p className="homeblog_text">{el.cont.textPart}</p>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>



            <div className="home_map">
                <div className="map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3047.604750291735!2d44.49313861570131!3d40.19560877698135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abd10bb0802ab%3A0x83af223c8c45f50d!2sAura%20Dental%20Clinic!5e0!3m2!1sru!2s!4v1637665081365!5m2!1sru!2s"
                        width="600"
                        height="450"
                        loading="lazy"
                        title="dfvnod"
                    ></iframe>
                </div>
            </div>

            <footer>
                <div className="footer2">
                    © All rights reserved
                </div>
            </footer>
        </>
    )
}

export default Home
