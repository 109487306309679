import React, { createContext} from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import {initializeApp} from 'firebase/app'
import {getStorage} from 'firebase/storage'
import {getFirestore} from 'firebase/firestore'

const firebaseConfig = {
  apiKey: "AIzaSyCkmMcaOA-O-z0wfS5kbjKf0mNobdDomAw",
  authDomain: "admin-panel-7ad24.firebaseapp.com",
  projectId: "admin-panel-7ad24",
  storageBucket: "admin-panel-7ad24.appspot.com",
  messagingSenderId: "472348868480",
  appId: "1:472348868480:web:86904ee542a6f5c0990b65"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app)
const db = getFirestore(app)

export const Context = createContext(null)

ReactDOM.render(
  <React.StrictMode>
    <Context.Provider value={{
      db,
      storage,
      store
    }}>
      <App />
    </Context.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
