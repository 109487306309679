import React from 'react'
import './HomeServices.scss'
import { Link } from 'react-router-dom'

import service1 from '../../assets/img/dental-care (3)1.png'
import service2 from '../../assets/img/dental-care (5)1.png'
import service3 from '../../assets/img/tooth (2)1.png'
import service4 from '../../assets/img/tooth1.png'
import service5 from '../../assets/img/dental-care (4)1.png'
import service6 from '../../assets/img/tooth (1)1.png'



function HomeServices() {
    return (
        <section className="services_part">
                <div className="sec_title">
                    <h6 className="container">
                        Մեր ծառայությունները
                    </h6>
                </div>
                <div className="boxes">
                    <Link to="/services" className="box">
                        <div className="icon">
                            <img src={service1} alt="" />
                        </div>
                        <h6>Պլոմբավորում</h6>
                    </Link>
                    <Link to="/services" className="box">
                        <div className="icon">
                            <img src={service2}  alt="" />
                        </div>
                        <h6>Արմատախողովակների բուժում</h6>
                    </Link>
                    <Link to="/services" className="box">
                        <div className="icon"><img src={service3}  alt="" /></div>
                        <h6>Ատամնաքարերի հեռացում</h6>
                    </Link>
                    <Link to="/services" className="box">
                        <div className="icon"><img src={service4}  alt="" /></div>
                        <h6>Լնդերի բուժում</h6>
                    </Link>
                    <Link to="/services" className="box">
                        <div className="icon">
                            <img src={service5}  alt="" />
                        </div>
                        <h6>Ատամների սպիտակեցում</h6>
                    </Link>
                    <Link to="/services" className="box">
                        <div className="icon"><img src={service6}  alt="" /></div>
                        <h6>Ատամների վերականգնում</h6>
                    </Link>
                </div>
            </section>
    )
}

export default HomeServices
