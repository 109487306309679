import React from 'react'
import './Accordion.scss'
import { MdKeyboardArrowDown } from 'react-icons/md';
import { MdKeyboardArrowUp } from 'react-icons/md';


function AccordionItem({faq, active, onToggle }) {
    const { question, answer } = faq;
  return (
    <li className={`accordion_item ${active ? "active" : ""}`}>
      <div className="button" onClick={onToggle}>
        {question}
        <span className="control">{active ? <MdKeyboardArrowUp className="arrow"/> : <MdKeyboardArrowDown className="arrow"/>} </span>
      </div>
      <div className={`answer_wrapper ${active ? "open" : ""}`}>
        <div className="answer">{answer}</div>
      </div>
    </li>
  );
}

export default AccordionItem
