import './Service.css'
import { useEffect, useState } from 'react'
import Plomb from './Plomb/Plomb'
import ServiceFooter from './ServiceFooter/ServiceFooter'
import Care from './Care/Care'
import Help from './Help/Help'
import Whitening from './Whitening/Whitening'
import TeethHelping from './TeethHelping/TeethHelping'
import { useRouteMatch } from 'react-router'

const Service = (props) => {
    const [currentService,setCurrentService] = useState('')
    const service = useRouteMatch('/services/:serviceId')
    const serviceId = service.params.serviceId

    
    useEffect(() => {
        if(serviceId == 'plombavorum'){
            setCurrentService('plombavorum')
        }
        if(serviceId == 'care'){
            setCurrentService('care')
        }
        if(serviceId == 'help'){
            setCurrentService('help')
        }
        if(serviceId == 'teeth-helping'){
            setCurrentService('teeth-helping')
        }
        if(serviceId == 'whitening'){
            setCurrentService('whitening')
        }
    },[serviceId])
    
    let content
    if(currentService == 'plombavorum'){
        content = 
        <>
            <Plomb history={props.history} />
        </>
    }
    if(currentService == 'care'){
        content = 
        <>
            <Care history={props.history} />
        </>
    }
    if(currentService == 'help'){
        content = 
        <>
            <Help history={props.history} />
        </>
    }
    if(currentService == 'teeth-helping'){
        content = 
        <>
            <Whitening history={props.history} /> {/* name wrong here was need be <TeethHelping /> but use like this */}
        </>
    }
    if(currentService == 'whitening'){
        content = 
        <>
            <TeethHelping history={props.history} /> {/* name wrong here was need be <Whitening /> but use like this */}
        </>
    }

    

    return (
        <main className='currentServiceMain'>
            {content}
            <ServiceFooter />
        </main>
    )
}


export default Service