import { combineReducers, createStore } from "redux";
import blogReducer from "./blog-reducer";




const reducers = combineReducers({
    blog:blogReducer
})

const store = createStore(reducers)

window.store = store

export default store