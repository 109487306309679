import './App.scss';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './components/pages/home/Home';
import About from './components/pages/about/About';
import Contact from './components/pages/contact/Contact';
import Services from './components/pages/services/Services';
import Header from './components/header/Header';
import ScrollToTop from './components/ScrollToTop';
import Blog from './components/pages/blog/Blog';
import BlogPage from './components/pages/blog/BlogPage';
import Admin from './components/pages/admin/Admin';
import {collection,getDocs} from 'firebase/firestore'
import { useContext, useEffect, useState } from 'react';
import { Context } from './index';
import Service from './components/pages/service/Service';


function App() {

  const {db,store} = useContext(Context)
  const blog = collection(db,'blog')
  const blogStore = store.getState().blog
  const [blogState,setBlogState] = useState(null)
  useEffect(() => {
    getDocs(blog).then(response => {
      response.docs.map(doc => {
        setBlogState([...blogStore, blogStore.unshift(doc.data())])
      })
    })
  }, [])

  return (
    <Router>
      <Header />
      <ScrollToTop />
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/services/:serviceId' component={Service} />
        <Route exact path='/admin' component={Admin} />
        <Route exact path='/about' component={About} />
        <Route exact path='/contact' component={Contact} />
        <Route exact path='/services' component={Services} />
        <Route exact path='/blog' component={Blog} />
        <Route exact path='/blog/:id' component={BlogPage} />
      </Switch>
    </Router>
  );
}

export default App;
