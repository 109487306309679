import {useContext, useState} from 'react'
import { doc,updateDoc } from 'firebase/firestore'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'
import {Context} from '../../../index'
import './Edit.css'

const Edit = (props) => {
    const {db,storage} = useContext(Context) 
    const [paragraph,setParagraph] = useState(props.data.filter(item => item.id === props.id).map(item => item.text))
    const [name,setName] = useState(props.data.filter(item => item.id === props.id).map(item => item.title))
    const [image,setImage] = useState(null)

    const [imageUrl,setImageUrl] = useState(null)
    const [imageUploaded1,setImageUploaded1] = useState(false)
    const [url,setUrl] = useState(props.data.filter(item => item.id === props.id).map(item => item.path))
    const [main,setMain] = useState(props.data.filter(item => item.id === props.id).map(item => item.textPart))
    const [paragraphChanged,setParagraphChanged] = useState(false)
    const [nameChanged,setNameChanged] = useState(false)
    const [urlChanged,setUrlChanged] = useState(false)
    const [mainChanged,setMainChanged] = useState(false)

    const saveEdit = () => {
        if (image) {
            uploadBytes(ref(storage, '/images/' + image.name), image).then(response => {
                console.log('file1 uploaded')
            })
            getDownloadURL(ref(storage, '/images/' + image.name),image.name)
                .then(url => {
                    setImageUrl(url)
                    console.log('file 1 url -' + imageUrl)
                })
        }

        if(paragraph !== '' && paragraphChanged === true){
            updateDoc(doc(db,'blog', props.id), {
                text: paragraph
            })
        }
        if(main !== '' && mainChanged === true){
            updateDoc(doc(db,'blog', props.id), {
                textPart: main,
            })
        }
        if(name !== '' && nameChanged === true){
            updateDoc(doc(db,'blog', props.id), {
                title: name,
            })
        }
        if(imageUrl !== null){
            updateDoc(doc(db,'blog', props.id), {
                img:imageUrl,
            }).then(() => {
                setImageUploaded1(true)
            })
            
        }else{
            setImageUploaded1(false)
        }
        if(url !== '' && urlChanged === true){
            updateDoc(doc(db,'blog', props.id), {
                path:url
            })
        }

    }
    console.log(props.oldValue.current)

    return (
        <div className=''>
            <div className='flex_column'>
                {props.data.filter(cont => cont.id === props.id).map(item => (
                    <>
                        <input value={url} className='edit_input' placeholder='Edit URL' onChange={(e) => {
                            setUrlChanged(true)
                            setUrl(e.target.value)

                        } } />
                        <input value={main} className='edit_input' placeholder='Edit main text' onChange={(e) => {
                            setMainChanged(true)
                            setMain(e.target.value)
                        }} />
                        <input value={name} className='edit_input' placeholder='Edit Title' onChange={(e) => {
                            setNameChanged(true)
                            setName(e.target.value)
                        }} />
                        <textarea value={paragraph} placeholder='Edit content text' className='second_input' onChange={(e) => {
                            setParagraphChanged(true)
                            setParagraph(e.target.value)
                        }} rows={"5"} cols="67" ></textarea>
                        <input type={'file'} onChange={(e) => setImage(e.target.files[0])} />
                        {imageUploaded1
                            ?
                            <span className='image_uploaded_text'>Image succsesfully uploaded</span>
                            :
                            <span className='image_dont_uploaded'>Try again image dont uploaded</span>
                        }
                        <div className='save_button'>
                        <button className='saveButton' onClick={saveEdit}>Save</button>
                        </div>
                    </>
                ))}

            </div>
        </div>
    )
}


export default Edit