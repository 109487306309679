import { useContext, useRef, useState } from 'react'
import { Context } from '../../../index'
import { setDoc, doc,deleteDoc, updateDoc } from 'firebase/firestore'
import { ref, getDownloadURL, uploadBytes } from 'firebase/storage'

import './Admin.css'
import Edit from './Edit'



const Admin = () => {
    const {store,db,storage} = useContext(Context)
    const [url,setUrl] = useState('')
    const [title,setTitle] = useState('')
    const [date,setDate] = useState('')
    const [mainText,setMainText] = useState('')
    const [contentText,setContentText] = useState('')
    const [file,setFile] = useState(null)
    const [fileUrl,setFileUrl] = useState(null)
    const [uploaded,setUploaded] = useState(null)
    const [divId,setDivId] = useState(null)
    const [query,setQuery] = useState('')
    const [editBox,setEditBox] = useState(false)
    const [login,setLogin] = useState(false)
    const [loginInput,setLoginInput] = useState('')
    let oldValue = useRef()
    const data = store.getState().blog
    data.sort((a,b) => new Date(a.date) - new Date(b.date))
    const searchedDate =  data.filter(blog => {
        if(query === ''){
            return blog
        }else if(blog.title.toLowerCase().includes(query.toLowerCase())){
            return blog
        }
    })

    
    const DateNow = Date.now()
    
    const addBlog = () => {
        const storageRef = ref(storage, '/images/' + file.name)
        uploadBytes(storageRef, file).then(response => {
            console.log('file1 uploaded')
        })
        if (file) {
            getDownloadURL(storageRef,file.name)
                .then(url => {
                    setFileUrl(url)
                })
        }

        if(fileUrl !== null){
            setDoc(doc(db,'blog', DateNow + 'id'), {
                id:DateNow + 'id',
                path: url,
                title:title,
                date: date,
                textPart:mainText,
                img:fileUrl,
                text: contentText

            }).then(setUploaded(true))
        }
        

    }

    const loginData = () => {
        if(loginInput === 'Forbes2000@!'){
            setLogin(true)
        }
    }


    return(
        <>
            {login == true 
            ?
            <div className='main'>
            <div className='inputs_flex'>
                <div className='input_flex_direction'>
                    <div className='adm_input'>
                        <input placeholder='URL' value={url} ref={oldValue} onChange={(e) => setUrl(e.target.value)} />
                    </div>
                    <div className='adm_input'>
                        <input placeholder='Title' value={title} onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    <div className='adm_input'>
                        <input placeholder='Date' value={date} onChange={(e) => setDate(e.target.value)} />
                    </div>
                    <div className='adm_input'>
                        <input placeholder='Main text' value={mainText} onChange={(e) => setMainText(e.target.value)} />
                    </div>
                    <div className='adm_input'>
                        <textarea placeholder='Content text' value={contentText} onChange={(e) => setContentText(e.target.value)}></textarea>
                    </div>
                    <div className='adm_input'>
                        <input type={'file'} onChange={(e) => setFile(e.target.files[0])} />
                    </div>
                    <div className='add_blog_div'>
                        <button className='add_blog' onClick={addBlog}>Add blog</button>
                    </div>
                    {uploaded 
                    ?
                    <span>Image succsesfuly uploaded</span> 
                    :
                    <span>Try again upload image</span>
                    }
                </div>
            </div>
            <div className='content_flex_direction'>
                <div className='search_flex'>
                    <input placeholder='Search blog' className='search_flex_input' value={query} onChange={(e) => setQuery(e.target.value)} />
                </div>
                    {searchedDate.map(item => (
                        <div className='item_column' key={item.id} id={item.id} onMouseEnter={(e) => {
                            setDivId(e.currentTarget.id)
                            
                        }}>
                            <div className='item'>
                                <div className='item_center'>
                                    <h1>{item.title}</h1>
                                    <img src={item.img} />
                                    <p>{item.textPart}</p>
                                    <button onClick={() => {
                                        deleteDoc(doc(db,'blog',divId))
                                    }}>Delete</button>
                                    <button onClick={() => setEditBox(!editBox)}>Edit</button>
                                    {editBox === true && item.id === divId 
                                    ?
                                    <Edit id={divId} data={data} oldValue={oldValue} /> 
                                    :
                                    null
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
        </div>
            :
                <div className='main'>
                    <div className='login_input_flex'>
                        <div className='login_input_flex_direction'>
                            <h3>Login</h3>
                            <input value={loginInput} onChange={(e) => setLoginInput(e.target.value)} type='password' />
                            <button onClick={loginData}>Login</button>
                        </div>
                    </div>
                </div>
            }
            
        </>
    )
}



export default Admin