import React from 'react'
import { Helmet } from "react-helmet";
import './Services.scss'
import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import idGenerator from '../../idGenerator'
import { servicesData } from '../../data'
import beforeAfter1 from '../../../assets/img/1.png'
import beforeAfter2 from '../../../assets/img/2.png'
import beforeAfter3 from '../../../assets/img/3.png'
import work1 from '../../../assets/img/Image-10.jpg'
import work2 from '../../../assets/img/Image-8.jpg'
import work3 from '../../../assets/img/pere.jpeg'

const work = [
    {
        id: idGenerator(),
        img: work1,
        title: "Արմատախողովակների բուժում",
    },
    {
        id: idGenerator(),
        img: work2,
        title: "Արմատախողովակների ապալիցքավորում և վերաբուժում",
    },
    {
        id: idGenerator(),
        img: work3,
        title: "Պերիօդոնտի բուժում",
    },
]

SwiperCore.use([Autoplay]);

function Services(props) {
    console.log(props)

    return (
        <>
            <Helmet>
                <title>Janoyan Dental | Services</title>
                <link rel="canonical" href="http://janoyandental.am/services" />
                <meta name="description" content="Services" />
            </Helmet>
            <main className="service-page">
                <div className="title_bg">
                    <h1>մեր ծառայությունները</h1>
                </div>
                <div className="service-items container">
                    <div className="swiper-services">
                        <Swiper
                            spaceBetween={30}
                            slidesPerView={1}
                            loop={true}
                            loopFillGroupWithBlank={true}
                            autoplay={{
                                delay: 3000,
                            }}
                        >
                            <SwiperSlide>
                                <img src={beforeAfter1} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={beforeAfter2} alt="" />
                            </SwiperSlide>
                            <SwiperSlide>
                                <img src={beforeAfter3} alt="" />
                            </SwiperSlide>

                        </Swiper>
                    </div>
                    <div className="services_text">
                        Բժիշկ-ստոմատոլոգ Աննա Ջանոյանն ապահովում է որակյալ ատամնաբուժական
                        խնամք Երևանում և պարտավորվում է օգնել ձեզ հասնել ձեր կատարյալ ժպիտին՝
                        օգտագործելով բուժման լավագույն միջոցները:
                        <br />
                        Բժիշկ-ստոմատոլոգ Աննա Ջանոյանն ունի փորձ հետևյալ ատամնաբուժական
                        ծառայություններում, որոնք անհրաժեշտ են ձեր ժպիտը առողջ և գրավիչ
                        պահելու համար: Սեղմեք ստորև բերված հղումները՝ տրամադրվող յուրաքանչյուր
                        ծառայությունների մասին ավելին իմանալու համար.
                    </div>
                    <div className="service-wrap">
                        {servicesData.map(item => (
                            <div className="description__card">
                                <div className="description__card-inner" onClick={() => props.history.push('services/' + item.path)}>
                                    <div className="description__card-face">
                                        <div className="icon"><img src={item.img} alt="" /></div>
                                        <h3>{item.name}</h3>
                                    </div>
                                    <div className="description__card-face back">
                                        <ul>
                                            <li>{item.desc[0]}</li>
                                            <li>{item.desc[1]}</li>
                                            <li>{item.desc[2]}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="service_imageitems">
                        {work.map(el => (
                            <div className="item">
                                <div className="image">
                                    <img src={el.img} alt="" />
                                </div>
                                <h3>{el.title}</h3>
                            </div>
                        ))}

                    </div>
                </div>
            </main>

            <footer>
                <div className="footer2">
                    © All rights reserved
                </div>
            </footer>
        </>
    )
}

export default Services
