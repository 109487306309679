import { Helmet } from "react-helmet";
import './Blog.scss';
import { Link } from 'react-router-dom';
import { BsCalendar2Week } from 'react-icons/bs';
import { useContext } from "react";
import { Context } from "../../../index";


function Blog() {
    const {store} = useContext(Context)
    const blogData = store.getState().blog

    return (
        <>
            <Helmet>
                <title>Janoyan Dental | Blog</title>
                <link rel="canonical" href="http://janoyandental.am/blog" />
                <meta name="description" content="Blog" />
            </Helmet>
            <main className="blogs_page">
                <div className="title_bg">
                    <h1>Բլոգ</h1>
                </div>
                <div className="blog">
                    <div className="container">
                        <div className="blog__main">
                            <div className="blog__card">
                                {blogData.map(el => (
                                    <div key={el.id} className="blog__post">
                                        <div className="blog__post-img">
                                            <img src={el.img} alt="Blog-Img" />
                                        </div>
                                        <Link to={{
                                            pathname: `/blog/${el.path}`,
                                            state: {
                                                id: el.id,
                                                title: el.title
                                            }
                                        }}
                                            className="blog__post-title">{el.title}</Link>
                                        <br />
                                        <span><BsCalendar2Week className="blog__post-date" /> {el.date}</span>
                                        <p>{el.textPart}...
                                            <Link to={{
                                                pathname: `/blog/${el.path}`,
                                                state: {
                                                    id: el.id,
                                                    title: el.title
                                                }
                                            }} className="button-link">Կարդալ ավելին</Link></p>


                                    </div>
                                ))}
                            </div>

                            <div className="blogPage__sidebar">
                                <h3>Վերջին նյութերը</h3>
                                {blogData.slice(0, 4).map(item => (
                                    <div>
                                        <Link to={{
                                            pathname: `/blog/${item.path}`,
                                            state: {
                                                id: item.id,
                                            }
                                        }} className="sidebar_post">
                                            <div className="post_img"><img src={item.img} alt="Blog-Img" /></div>
                                            <div className="post_title">
                                                <h4> {item.title} </h4>
                                                <p>{`${item.textPart.slice(0, 35)}...`}</p>
                                            </div>

                                        </Link>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <footer>
                    <div className="footer2">
                        © All rights reserved
                    </div>
                </footer>
            </main>
        </>
    )
}

export default Blog;
