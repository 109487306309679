import React, { useState } from 'react';
import { faqs } from '../../components/data';
import AccordionItem from './AccordionItem';
import './Accordion.scss';
import faqs_img from '../../assets/img/faq.png';

function Accordion() {
    const [clicked, setClicked] = useState("0");
    const handleToggle = (index) => {
        if (clicked === index) {
            return setClicked("0")
        }
        setClicked(index)
    };
    return (
        <>
        <div className="sec_title">
        <h6 className="container">Հաճախ տրվող հարցեր</h6>
    </div>
        <div className="container accordion_comp">
            <ul className="accordion">
                {faqs.map((faq, index) => (
                    <AccordionItem  
                    key={index} 
                    faq={faq}
                    onToggle={() => handleToggle(index)}
                    active={clicked === index} />
                ))}
            </ul>
            <div className="faqsImage">
                <img src={faqs_img} alt="" />
            </div>
        </div>
        </>
    )
}

export default Accordion
