import React from 'react'
import { Helmet } from "react-helmet"
import './Contact.scss'
import { Link } from 'react-router-dom'
import location from "../../../assets/img/placeholder.png"
import email from "../../../assets/img/email.png"
import call from "../../../assets/img/phone-call.png"
import user from "../../../assets/img/user.png"

function Contact() {

    return (
        <>
            <Helmet>
                <title>Janoyan Dental | Contact US</title>
                <link rel="canonical" href="http://janoyandental.am/contact" />
                <meta name="description" content="Contact US" />
            </Helmet>
            <section className="contact_us">
                <div className="title_bg">
                    <h1>Հետադարձ կապ</h1>
                </div>
                <div className="contact_data container">
                    <aside className="widget_contacts">
                        <ul>
                            <li>
                                <div className="contact-text">
                                    <strong>Հասցե</strong>
                                    <p>
                                        Երևան,
                                        Մարշալ Բաղրամյան պող. 68/26
                                    </p>
                                </div>
                                <div className="icon">
                                    <img src={location} alt="" />
                                </div>
                            </li>
                            <li>
                                <div className="contact-text">
                                    <strong>Էլ-Փոստ</strong>
                                    <p>
                                        <Link to={{ pathname: "mailto:info@janoyandental.am" }} target="_blank" >info@janoyandental.am </Link>
                                    </p>
                                </div>
                                <div className="icon"><img src={email} alt="" /></div>
                            </li>
                            <li>
                                <div className="contact-text">
                                    <strong>Հեռախոսահամար</strong>
                                    <p>
                                        <Link to={{ pathname: "tel:+37433778783" }} target="_blank" >+374 33 77-87-83 </Link>
                                    </p>
                                </div>
                                <div className="icon">
                                    <img src={call} alt="" />
                                </div>
                            </li>
                        </ul>
                    </aside>

                    <div className="right_map">
                        <div className="map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3047.604750291735!2d44.49313861570131!3d40.19560877698135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abd10bb0802ab%3A0x83af223c8c45f50d!2sAura%20Dental%20Clinic!5e0!3m2!1sru!2s!4v1637665081365!5m2!1sru!2s" width="600" height="500" loading="lazy" title="34fegd"></iframe>
                        </div>
                    </div>
                </div>

                <div className="contact_form container">
                    <h2>Գրանցվել խորհրդատվության</h2>
                    <form action="">
                        <div className="input_part">
                            <span><img src={user} alt="" /></span>
                            <input type="text" placeholder="Անուն" />
                        </div>
                        <div className="input_part">
                            <span><img src={call} alt="" /></span>
                            <input type="tel" placeholder="Հեռախոսահամար" />
                        </div>
                        <div className="input_part">
                            <span><img src={email} alt="" /></span>
                            <input type="mail" placeholder="Էլ-հասցե" />
                        </div>
                        <textarea
                            name=""
                            id=""
                            cols="30"
                            rows="10"
                            placeholder="Գրեք Ձեր հաղորդագրությունը կամ հարցը"
                        ></textarea>
                        <div className="button">
                            <a href="/">Գրանցվել</a>
                        </div>
                    </form>
                </div>
            </section>
            <footer>
                <div className="footer2">
                    © All rights reserved
                </div>
            </footer>
        </>
    )
}

export default Contact
