import './Help.css'
import image2 from '../../../../assets/img/image 2.png'
import ticky from '../../../../assets/img/ticky.png'

const Help = (props) => {
    return (
        <div className='helpMain'>
            <div className='topPartHelp'>
                <div className='centerPart'>
                    <div className='serviceMiniTextFlexHelp'>
                        <h1 className='serviceTextHelp'>Լնդերի բուժում </h1>
                        <span className='serviceMiniTextHelp'>Լնդային խնդիրները բերանի խոռոչի հաջորդ առանցքային խնդիրներից են, քանի որ բերանի խոռոչի լորձաթաղանթի բորբոքումը կարող է  բազմաթիվ տհաճ իրավիճակներ ստեղծել։</span>
                        <button className='serviceButtonJoin' onClick={() => {
                                props.history.push('/contact')
                            }}>ՀԵՐԹԱԳՐՈՒՄ</button>
                    </div>
                </div>
            </div>
            <div className='fourSection'>
                <h2>Լնդային բորբոքումների պատճառները</h2>
                <span>

                    Լնդային բորբոքումն ի հայտ է գալիս այն դեպքում, երբ  ախտահարվում են պարոդոնտ հյուսվածքները։ Խնդրի նախնական զարգացման փուլը ընդունված է անվանել գինգիվիտ, որին ապա հաջորդում է պարոդոնտիտը։ <br /> <br />

                    Բնականաբար, հմուտ մասնագետի հսկողության պայմաններում  կանխվում է հետագա բորբոքումը։ <br /> <br />

                    Բերանի խոռոչի խնդիրները բազմաթիվ են և բազմազան, սակայն ոչ բոլոր խնդիրներն են, որ ենթադրում են  անհատական խնամք, շատ հաճախ  լորձաթաղանթի բորբոքումը ենթադրում է լուրջ մասնագիտական մոտեցում։ <br />



                </span>
            </div>
            <div className='fiveSectionMain1'>
                <div className='fiveSectionHelp'>
                    <span className='moreGrayHelp'>Լնդային բորբոքումներ լինում են հետևյալ պատճառներով</span> <br />
                    <span className='grayHelp'>

                        Բերանի խոռոչի հիգիենային ոչ պատճաշ հետևելը, որի հետևանքով փոփոխվում է բերանի բնական միկրոֆլորան և պայմաններ են ստեղծվում բակտերիաների թափանցման համար, <br /> <br />

                        ✓ Սթրես <br />
                        ✓ Վատ սննդակարգ <br />
                        ✓ Քիմիոթերապիա <br />
                        ✓ Իմունային հիվանդություններ <br />
                        ✓ Լցման, օրթոդոնտիկ կոնստրուկցիաների քրոնիկ տրավմաներ <br />
                        ✓ Սխալ կծվածք <br />
                        ✓ Հորմոնային շեղումներ <br />
                        ✓ Ծխախոտ <br />
                        ✓ Այլ <br />

                    </span>
                </div>
            </div>
            <div className='fiveSectionMain2'>
                <div className='fiveSectionHelp2'>
                    <span className='moreGrayHelp'>Ինչպե՞ս հասկանալ, որ առկա է լնդերի բորբոքում</span> <br />
                    <span className='grayHelp'>

                        Եթե <br /> <br />

                        ✓ Լնդերը կարմրություն ունեն <br />
                        ✓ Այտուցված են<br />
                        ✓ Առկա է սպիտակ փառ <br />
                        ✓ Լնդերն արնահոսում են <br />
                        ✓ Բերանի խոռոչում տհաճ համի և հոտի զգացողություն է <br />
                        ✓ Ատամների վզիկները մերկացած են <br />
                        ✓ Ատամների շարժուն են <br /> <br />

                        ապա, կարելի է ենթադրել, որ ճիշտ ժամանակն է դիմել Janoyan Dental-ի հմուտ մասնագետին։

                    </span>
                </div>
            </div>
            <div className='sixSectionHelp'>
                <img src={image2} alt="" />
                <div className='sixSectionHelpRightPart'>
                    <h2>Բուժման իրականացումը</h2> <br />
                    <span>
                        Հաշվի առնելով, որ լնդերի հետ կապված խնդիրներն անհատական բնույթ են կրում, բուժումը ևս անհատական մոտեցում է պահանջում։ <br /> <br />

                        Խնդրի լիարժեք ախտորոշումից առաջ իրականացվում է մասնագիտական հիգիենա, որոշվում է բորբոքման խորությունը։ <br />
                        Նշանակվում են  բուժիչ քսուքներ, դեղաբույսերով ողողումներ, եթե կարիք կա՝ նաև հակաբորբոքային դեղամիջոցներ։ <br /> <br />

                        Որքան ավելի վաղ ախտորոշեք խնդիրը, այնքան ավելի կարճ կլինի դեպի առողջացումը ճանապարհը։ <br />

                    </span>
                </div>
            </div>
            <div className='sevenToEightmainBlock'>
                <div className='sevenSectionHelp'>
                    <h2 className='sevenSectionBigText'>Պարոդոնտ</h2> <br />
                    <h2 className='sevenSectionBigText'>(շուրջարմատային հրուսվածքի բորբոքում)</h2> <br /> <br />
                    <h2 className='sevenSectionMiniText'>Պարոդոնտի առաջացման պատճառներն են՝</h2>
                    <div className='bottomPartSevenSectionHelp'>
                        <div className='sevenSectionBlocks'>
                            <img src={ticky} alt="" />
                            <span>Բորբոքում</span>
                        </div>
                        <div className='sevenSectionBlocks'>
                            <img src={ticky} alt="" />
                            <span>Տրավմա</span>
                        </div>
                        <div className='sevenSectionBlocks'>
                            <img src={ticky} alt="" />
                            <span>Դեղորայքի երկրորդային ազդեցություն</span>
                        </div>
                    </div>
                </div>
                <div className='bottomPartSevenSectionHelp'>
                    <div className='sevenSectionBlocks mt213'>
                        <img src={ticky} alt="" />
                        <span>Մարգինալ կամ լնդային</span>
                    </div>
                    <div className='sevenSectionBlocks mt213'>
                        <img src={ticky} alt="" />
                        <span>Գագաթային կամ ապիկալ</span>
                        <p>
                            սուր պերիոդոտիտ՝ ինֆեկցիոն, տոկսիկ, տրավմատիկ <br /> <br />

                            քրոնիկ պերիօդոնտիտ՝ ֆիբրոզ գրանուլեմատոզ, գրանուլացվող <br /> <br />

                            քրոնիկական պերիօդոնտիտի <br />
                            սրացում

                        </p>
                    </div>
                </div>
                <div className='hrSectionHelpFlex'>
                    <div className='hrSectionHelp'></div>
                </div>
                <div className='eightSection'>
                    <span>
                        Սուր պարոդոնտները դասակարգվում են նաև սուր շճային և սուր թարախային, որոնցից սուր շճայինը ընթանում է երկու փուլով՝ ինտոքսիկացիոն և էքսուդացիայի։ <br /> <br />

                        Առաջինն ուղեկցվում է անհանգստացնող ցավերով, ցավ՝ հպվելուց, միևնույն ժամանակ չի նկատվում լորձաթաղանթի բորբոքում։  Էքսուդացիոն փուլի դեպքում պացիենտը նշում է «գերաճած ատամի զգացողություն»։ <br /> <br />

                        Նկատվում է ատամի  շարժ,լորձաթաղանթը կարմրած է (հիպերեմիկ), այտուցված, շոշափումից առաջանում է  ցավ։ <br /> <br />

                        Սուր թարախային պարոդոնտի դեպքում ցավը անընդմեջ է, տրոփող, ճառագայթվում է եռվորյակ նյարդի ճյուղերով, նկատվում է պացիենտի բերանի բացման սահմանափակում, լորձաթաղանթը կարմրած (հիպերեմիկ) է, ավշային հանգույցները մեծացած են, ցավոտ, շարժուն , ջերմաստիմանը կարող է բարձրանալ մինչև 39 աստիճան։ <br /> <br />

                        Պարզ է, որ նման բարդ խնդիրները ենթադրում են մասնագիտական մոտեցում <br />

                    </span>
                </div>
            </div>
            <div className='nineSectionMain'>
                <div className='nineSection'>
                    <h2>Պարոդոնտի բուժումը</h2>
                    <span>
                        Պարոդոնտիտի բուժումը կախված է խնդրի բարդությունից։ <br /> <br />

                        Մինչդեռ հիմնական բուժման ընթացքը կարելի է ասել՝ նմանատիպ է։
                        Բուժումը սկսվում է կարիոզ խոռոչի մշակմամբ, ապա քիմիական և մեխանիկական մշակման է ենթարկվում արմատախողովակը, որտեղ էլ տեղադրվում է բուժիչ լիցքը։<br />
                        Վերջինս կախված է պերիդոնտի տեսակից և բարդությունից և կարող է իրականացվել տարբեր ժամանակահատվածներում։<br /> <br />

                        Երբեմն բուժման ընթացքը կարող է պահանջել մի քանի այց՝ նույնիսկ վիրաբուժական միջամտություն, եթե խնդիրը բավականաչափ բարդացած է։<br /> <br /> 
                        Բուժումն ավարտվում է վերջնական լիցքավորմամբ և ատամնապսակի վերականգնումով։ <br />
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Help