import './TeethHelping.css'
const TeethHelping = (props) => {
    console.log(props.match)
    
    return (
        <div className='teethHelpingMain'>
            <div className='topPartTeethHelping'>
                <div className='centerPartTeethHelping'>
                    <div className='serviceMiniTextFlex'>
                        <h1 className='serviceText'>Ատամների սպիտակեցում</h1>
                        <span className='serviceMiniText'>Ցանկանու՞մ եք ունենալ ավելի պայծառ ժպիտ, այդ դեպքում սպիտակցված ատամներ ունենալը կարևոր նախապայման է։ Ատամների սպիտակեցումն իրականացվում է երկու եղանակով՝  մասնագիտական և  օժանդակող տնային խնամք։</span>
                        <button className='serviceButtonJoin' onClick={() => {
                                props.history.push('/contact')
                            }}>ՀԵՐԹԱԳՐՈՒՄ</button>
                    </div>
                </div>
            </div>
            <div className='secondSectionTeethHelpingMain'>
                <div className='secondSectionTeethHelping'>
                    <span>

                        Ատամների հետ կապված խնդիրները բացահայտելու համար նախ հասկանանք, թե ինչպիսի՞ն է ատամի անատոմիան։ <br /> <br />

                        Ատամի տեսանելի հատվածը պսակն է, իսկ ահա անտեսանելի հատվածը՝ արմատը։ <br /> <br />

                        Թերապևտիկ ստոմատոլոգիական միջամտությունները նպաստում են խնդիրների կանխմանը, սակայն չեն բացառում այնպիսի երևույթների ի հայտ գալը, ինչպիսին է պուլպան (կակղանը)։ <br /> <br />

                        Վերջինս բաղկացած է  արյան անոթներից և նյարդերից։ <br />
                    </span>

                </div>
            </div>
            <div className='secondSectionTeethHelpingMain2'>
                <div className='secondSectionTeethHelping'>
                    <h2>Ի՞նչ է հանգեցնում ատամի էմալի գույնի փոփոխությանը։</h2>
                    <span>


                        Հետևյալ սննդատեսակների մշտական առկայությունը մեր սննդակարգում հանգեցնում է ատամի էմալի գույնի փոփոխությանը՝ <br /> <br />

                        ✓ Սեւ թեյ եւ սուրճ <br /> <br />

                        ✓ Սպիտակ եւ կարմիր գինի <br /> <br />

                        ✓ Գազավորված ըմպելիքներ (մուգ և բաց գազավորված ըմպելիքներ) <br /> <br />

                        ✓ Հատապտուղներ եւ այլ խիստ ներկված ապրանքներ <br /> <br />

                        ✓ Թանձրուկներ (սոյայի, տոմատի, կարրի և այլն) <br /> <br />

                        Վերջինները ենթադրում են, որ ատամի նկատմամբ խնամքը և սպիտակեցումը պետք է լինեն <br />
                        մշտական և իրականացվեն  Janoyan Dental-ի հմուտ մասնագետի կողմից։

                    </span>

                </div>
            </div>
            <div className='secondSectionTeethHelpingMain'>
                <div className='secondSectionTeethHelping'>
                    <h2>Տարբերակում են պրոֆեսիոնալ սպիտակեցման հետևյալ մեթոդները՝</h2>
                    <span>


                        <b>Քիմիական</b> սպիտակեցումը իրականացվում է հատուկ գելի միջոցով, առանց լույսի ազդեցությամբ։ <br /> <br />

                        Գելը հիմնականում կազմված է ջրածնի պերօքսիդից կամ կարբամիդիպերօքսիդից(15% կամ 25%): <br /> <br />

                        <b>Լուսային</b> սպիտակեցումը ենթադրում է մասնագիտական մոտեցում՝ 30-35% ջրածնի պերօքսիդով, որը ակտիվանում է լամպի լույսի միջոցով։ <br /> <br />

                        Հիմնականում սպիտակեցումը իրականացվում է մեկ այցի միջոցով։ <br /> <br />

                        <b>Zoom 4</b>-ի դեպքում ի տարբերություն լուսային սպիտակեցման՝ գելի ջրածնի պերօքսիդը 25% է, որը ավելի քիչ վնասակար է համարվում էմալի համար։ <br /> <br />

                        Գելը պարունակում է նաև կալցիումի ֆոսֆատ, որը ավելի է ամրեցնում էմալը։ <br /> <br />

                        Վերջինս աշխատում  է սառը ճառագայթի  սկզբունքով և համարվում է ներկայիս ամենաանվնաս, անցավ և ամենաարդյունավետ ատամները սպիտակեցնող միջոցը։ <br />


                    </span>

                </div>
            </div>
        </div>
    )
}


export default TeethHelping