import styles from './Care.module.css'
import ticky from '../../../../assets/img/ticky.png'

const Care = (props) => {
    return (
        <div className={styles.mainCart}>
            <div className={styles.topPart}>
                <div className={styles.centerPart}>
                    <div className={styles.serviceMiniTextFlex}>
                        <h1 className={styles.serviceText}>ՄԱՍՆԱԳԻՏԱՑՎԱԾ ԽՆԱՄՔ</h1>
                        <span className={styles.serviceMiniText}>Չնայած այն հանգամանքին, որ  բերանի խոռոչի խնամքի համար մենք ջանք չենք խնայում, երբեմն իդեալական հիգիենա անհնար է ապահովել։ Բերանի խոռոչի որոշ հատվածներ, այդուհանդերձ անհասանելի են ատամի խոզանակի կամ ատամնաթելերի համար։</span>
                        <button className={styles.serviceButtonJoin} onClick={() => {
                                props.history.push('/contact')
                            }}>ՀԵՐԹԱԳՐՈՒՄ</button>
                    </div>
                </div>
            </div>
            <div className={styles.fourSection}>
                <h2>Ինչու՞ է բերանի խոռոչի մասնագիտական խնամքն այդքան կարևոր</h2>
                <span>

                    Ատամների վրա առաջացող փափուկ,սպիտակ թաղանթը կարող է ի հայտ գալ ինչպես սննդից, այնպես էլ՝ բերանի խոռոչում գտնվող բակտերիաներից։ <br /><br />

                    Եթե ​​ատամնափառը երկար ժամանակ մնում է ատամի վրա, բակտերիաները ակտիվացնում են քիմիական ռեակցիաները (հատկապես ակտիվանում են շաքար կամ օսլա պարունակող սննդամթերքից), որի արդյունքում առաջանում են տարբեր թթուներ: <br /><br />

                    Թթուները վնասում են ատամի մակերեսը՝ այն սկսում է քայքայել ատամը, առաջանում է կարիես,ապա՝ խոռոչ։ <br /><br />

                    Ատամնաքարի նստվածքները չեն կարող հեռացվել բերանի խոռոչի անհատական ​​հիգիենայի միջոցով, ուստի անհրաժեշտ է լինում իրականացնել բերանի խոռոչի մասնագիտական խնամք։  <br />



                </span>
            </div>
            <div className={styles.fourSectionMainBlock}>
                <div className={styles.fourSection}>
                    <h2>Ինչպե՞ս է այն իրականացվում</h2>
                    <span>

                        Մասնագետը գնահատում է հիվանդի բերանի խոռոչի վիճակը, տեղեկանում է  տանը կիրառվող մեթոդների մասին, բացատրում, թե ինչպես ճիշտ և արդյունավետ մաքրել ատամները, պատասխանում է հարցերին և խորհուրդներ տալիս։ <br /> <br />

                        Ատամնաքարերի մեխանիկական հեռացումից (օգտագործվում է ուլտրաձայնային սքեյլեր) հետո ատամների տեսքը զգալիորեն բարելավվում է։ <br /> <br />

                        Վերջում ատամները փայլեցվում են խոզանակներով, պատում փայլեցնող մածուկով։<br /> <br />

                        Ձեզ հաճելիորեն կզարմացնեն անցավ մասնագիտական միջամտութունից հետո նկատելի փոփոխությունները։<br /> <br />

                        Ատամի մակերեսը կդառնա հարթ և փայլուն, ինչպես ապակին, իսկ թարմության ու մաքրության զգացումը ձեզ իսկապես կուրախացնի:<br /> <br />

                        Ըստ բարդության, այս ամբողջ պրոցեսը կարող է տևել 20 րոպեից մինչև 1 ժամ։<br />



                    </span>
                </div>
            </div>
            <div className={styles.secondSection}>
                <h2 className={styles.secondSectionFirstText}>Ատամնափառի առաջացման  փուլերը</h2>
            </div>
            <div className={styles.thirdSection}>
                <div className={styles.thirdSectionBlocks}>
                    <img src={ticky} alt="" />
                    <span>Առաջանում է լվանալուց 4 ժամ հետո</span>
                </div>
                <div className={styles.thirdSectionBlocks}>
                    <img src={ticky} alt="" />
                    <span>Առաջանում է  4-7 ժամվա ընթացքում</span>
                </div>
                <div className={styles.thirdSectionBlocks}>
                    <img src={ticky} alt="" />
                    <span>Առաջանում է  7 ժամից հետո: Այս փուլում ատամնափառը դառնում է տեսանելի</span>
                </div>
            </div>
            <div className={styles.fourSectionSecondBlock}>
                <div className={styles.fourSection}>
                    <h2>Որոշ խորհուրդներ</h2>
                    <span>

                        Խոզանակեք ձեր ատամները օրը 2 անգամ <br /> <br />

                        Խոզանակելու պրոցեդուրան պետք է 2-3 րոպեից պակաս չտևի <br />
                        Մաստակ օգտագործեք յուրաքանչյուր կերակուրից մի քանի րոպե հետո կամ ողողեք բերանը ջրով <br /> <br />

                        Սա ավելի արագ է հեռացնում սննդի մնացորդները <br /> <br />

                        Մաքրեք ձեր ատամները ուղղահայաց «վեր-ներքև» շարժումով, այլ ոչ թե հորիզոնական «առաջ և հետ» <br /> <br />

                        Փորձեք խոզանակել ձեր ատամների ամբողջ մակերեսը <br /> <br />

                        Օգտագործեք կաթնամթերք. դրանք օգտակար են ատամների համար կալցիումի պարունակության շնորհիվ, որը հեշտացնում է թթուներից վնասված ատամի մակերեսների վերականգնումը: <br />




                    </span>
                </div>
            </div>
        </div>
    )
}


export default Care