import React from 'react'
import { Helmet } from "react-helmet";
import './About.scss'
import CertificatesSlider from '../../certificatesSlider/CertificatesSlider'
import aboutimg from '../../../assets/img/dr.jpg'

function About() {

    return (
        <>
            <Helmet>
                <title>Janoyan Dental | About Us</title>
                <link rel="canonical" href="http://janoyandental.am/about" />
                <meta name="description" content="Janoyan Dental | About Us" />
            </Helmet>
            <main className="main">
                <div className="title_bg">
                    <h1>մեր մասին</h1>
                </div>
                <div className="main__content container">
                    <div className="main__image">
                        <img src={aboutimg} alt="" />
                    </div>
                    <div className="desc">
                        <div className="for_bg title">
                            <h2> Բժիշկ-ստոմատոլոգ   <br /> Աննա Ջանոյան </h2>
                        </div>
                        <p>
                            Աննա Ջանոյանը մասնագիտությամբ բժիշկ-ստոմատոլոգ է: Սովորել է ԵՊԲՀ բակալավրիատում՝ 2006-2010թթ.
                            և մագիստրատուրայում ՝ 2010-2012թթ.։
                            <br /> <br />
                            2012-2013թթ. մասնագիտացել է ԵՊԲՀ ինտերնատուրայում՝ «Ընդհանուր ստոմատոլոգիական գործունեություն» մասնագիտությամբ:
                            <br /> <br />
                            Բժշկուհի Աննա Ջանոյանն ունի Ընդհանուր գործունեության ստոմատոլոգի որակավորում և 8 տարվա աշխատանքային փորձ։ Իր առաքելությունն է համարում ապահովել
                            ամենաբարձր որակի ատամնաբուժական խնամք՝ հարմարավետ, պրոֆեսիոնալ և անցավ պայմաններում:
                        </p>
                    </div>
                </div>
                <CertificatesSlider />

                <footer>
                    <div className="footer2">
                        © All rights reserved
                    </div>
                </footer>
            </main>
        </>
    )
}

export default About
