import styles from './Plomb.module.css'
import ticky from '../../../../assets/img/ticky.png'
const Plomb = (props) => {
    
    return (
        <div className={styles.mainPlomb}>
            <div className={styles.topPartPlombFlex}>
                <div className={styles.topPartPlomb}>
                    <div className={styles.centerPart}>
                        <div className={styles.serviceMiniTextFlex}>
                            <h1 className={styles.serviceText}>ՊԼՈՄԲԱՎՈՐՈՒՄ</h1>
                            <span className={styles.serviceMiniText}>Պլոմբավորումը թերապևտիկ միջամտություն է ուղղված՝  ատամի պսակի անատոմիական, էսթետիկ տեսքի և ֆունկցիոնալ գործողությունը վերականգնելուն։</span>
                            <button className={styles.serviceButtonJoin} onClick={() => {
                                props.history.push('/contact')
                            }}>ՀԵՐԹԱԳՐՈՒՄ</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.secondSection}>
                <h2 className={styles.secondSectionFirstText}>ՊԼՈՄԲԱՎՈՐՄԱՆ ԾԱՌԱՅՈՒԹՅՈՒՆՆԵՐ</h2>
                <span className={styles.secondSectionSecondText}>Աննա Ջանոյանը առաջարկում է հետևյալ պլոմբավորման ծառայությունները՝</span>
            </div>
            <div className={styles.thirdSection}>
                <div className={styles.thirdSectionBlocks}>
                    <img src={ticky} />
                    <span>Ատամի ախտահարված հյուսվածքի հեռացում</span>
                </div>
                <div className={styles.thirdSectionBlocks}>
                    <img src={ticky} />
                    <span>Ատամի պսակի բացակայող հատվածի վերականգնում</span>
                </div>
                <div className={styles.thirdSectionBlocks}>
                    <img src={ticky} />
                    <span>Դիմային ատամների կոմպոզիտային պլոմբանյութով վերականգնում</span>
                </div>
            </div>
            <div className={styles.thirdSectionButtonFlex}>
                <button className={styles.contactButtonPlomb} onClick={() => {
                                props.history.push('/contact')
                            }}>ԿԱՊՎԵԼ</button>
            </div>
            <div className={styles.fourSection}>
                <h2>Ե՞ՐԲ Է ԱՆՀՐԱԺԵՇՏ ՊԼՈՄԲԱՎՈՐՈՒՄԸ</h2>
                <span style={{ marginTop: "30px" }}>
                    Ատամների պլոմբավորման պատճառներն են՝ կարիոզային և ոչ կարիոզային ախտահարումները։ <br /><br />

                    Կարիոզային ախտահարման դեպքում պլոմբավորումը կատարվում է նախնական փուլում, օրինակ՝ մակերեսային կարիեսի ժամանակ, երբ խախտված է միայն էմալի ամբողջականությունը:<br /><br />
                    Այս դեպքում կանխում ենք կարիեսի հետագա զարգացումը:<br /><br />

                    Ինչքան մեծ մակերես է ընդգրկում կարիոզային խոռոչը՝ այնքան հնարավոր չի լինում վերականգնել պլոմբանյութի միջոցով, և ատամը վերականգնվում է  օրթոպեդիկ կոնտսրուկցիայի միջոցով։<br /><br />

                    Խորանիստ կարիեսի դեպքում (ատամի պսակի 50% և ավել ախտահարում)  ախտահարված են լինում դենտինի խորանիստ շերտերը և այն կարող է թափանցել ատամի պուլպան(կակղանը):<br /><br />
                    Այս դեպքում բուժումը լինում է ավելի երկարատև և ծախսատար։<br /><br />

                    Ոչ կարիոզային ախտահարումների ժամանակ ատամը հնարավոր է լինում վերականգնել կոմպոզիտային պլոմբանյութի միջոցով, եթե շատ վատ վիճակում չէ։<br /><br />
                </span>
            </div>
            <div className={styles.fiveSection}>
                <div className={styles.fiveSectionBlock1}>
                    <div className={styles.fiveSectionBlock1TopContent}>ԿՈՄՊՈԶԻՏԱՅԻՆ ԱԽՏԱՀԱՆՈՒՄՆԵՐ</div>
                    <div className={styles.fiveSectionBlock1CenterContent}>
                        <span>
                            ✓ Կարիես բծի շրջանում <br /> <br />

                            ✓ Մակերեսային կարիես<br /> <br />

                            ✓ Միջին կարիես<br /> <br />

                            ✓ Խորանիստ կարիես<br /> <br />

                        </span>
                    </div>
                </div>
                <div className={styles.fiveSectionBlock2}>
                    <div className={styles.fiveSectionBlock2TopContent}>ՈՉ ԿՈՄՊՈԶԻՏԱՅԻՆ ԱԽՏԱՀԱՆՈՒՄՆԵՐ</div>
                    <div className={styles.fiveSectionBlock2CenterContent}>
                        <span className={styles.fiveSectionSpan}>
                            ✓ Ատամի սաղմի զարգացման ընթացքում<br /> <br />

                            ✓ Մինչ ատամի ծկթումը<br /> <br />

                            ✓ Ատամի ծկթումից հետո<br /> <br />

                        </span>
                    </div>
                </div>
            </div>
            <div className={styles.sixSection}>
                <h2>ՕԳՏԱԳՈՐԾՎՈՂ ՊԼՈՄԲԱՆՅՈՒԹԵՐԸ</h2>
                <span>
                    Պլոմբավորումը թերապևտիկ միջամտություն է ուղղված՝  ատամի պսակի անատոմիական, էսթետիկ տեսքի և ֆունկցիոնալ գործողությունը վերականգնելուն։ <br /> <br />

                    Մեծ պահանջարկ են վայելում կոմպոզիտային լուսային կարծրացման պլոմբանյութերով ստոմոտոլոգիական ծառայությունները։ <br /> <br />

                    Խնդրի բարդությունից, ամրությունից և էսթետիկ նկատառումներից ելնելով՝  կիրառվում են տարբեր տեսակի պլոմբանյութեր. <br />
                </span>
            </div>
            <div className={styles.sevenSectionMainBlock}>
                <div className={styles.sevenSection}>
                    <div className={styles.sevenSectionPart1}>
                        <img src={ticky} className={styles.sevenSectionLeftBlock} alt="" />
                        <div className={styles.sevenSectionRigthBlock}>
                            <div className={styles.sevenSectionTextBlock}>
                                <h2>ՄԵՏԱՂԱԿԱՆ ՊԼՈՄԲԱՆՅՈՒԹ</h2>
                            </div>
                            <div className={styles.sevenSectionLeftBlockResponsive}></div>
                            <span>(ամալգամ, հալիումի համաձուլվածք, ոսկուց լցանյութեր)․ ներկայումս այս տեսակը շատ հազվադեպ է օգտագործվում:</span>
                        </div>
                    </div>
                    <div className={styles.sevenSectionPart1}>
                        <img src={ticky} className={styles.sevenSectionLeftBlock} alt="" />
                        <div className={styles.sevenSectionRigthBlock}>
                            <div className={styles.sevenSectionTextBlock}>
                                <h2>ՑԵՄԵՆՏ</h2>
                            </div>
                            <div className={styles.sevenSectionLeftBlockResponsive}></div>
                            <span>(հանքային, պոլիմերային տեսակ)․ հիմնականում օգտագործում է ապակեիոնոմերային պոլիմերային խմբի ցեմենտը։</span>
                        </div>
                    </div>
                </div>
                <div className={styles.sevenSection}>
                    <div className={styles.sevenSectionPart1}>
                    <img src={ticky} className={styles.sevenSectionLeftBlock} alt="" />
                        <div className={styles.sevenSectionRigthBlock}>
                            <div className={styles.sevenSectionTextBlock}>
                                <h2>ԿՈՄՊՈԶԻՏԱՅԻՆ ՊԼՈՄԲԱՆՅՈՒԹ</h2>
                            </div>
                            <div className={styles.sevenSectionLeftBlockResponsive}></div>
                            <span>(քիմիական, լուսային, երկակի, ջերմային կարծրացման մեթոդներ)</span>
                        </div>
                    </div>
                    <div className={styles.sevenSectionPart1}>
                    <img src={ticky} className={styles.sevenSectionLeftBlock} alt="" />
                        <div className={styles.sevenSectionRigthBlock}>
                            <div className={styles.sevenSectionTextBlock}>
                                <h2>ԿՈՄՊՈՆԵՆՏՆԵՐ</h2>
                            </div>
                            <div className={styles.sevenSectionLeftBlockResponsive}></div>
                            <span>(կոմպոզիտների և ապակե իոնոմերային ցեմենտի համակցում):</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Plomb