import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import './Header.scss';
import { Link, NavLink } from 'react-router-dom';
import { RiFacebookFill } from 'react-icons/ri';
import { FiInstagram } from 'react-icons/fi';
import { GiHamburgerMenu } from 'react-icons/gi';
import logo from '../../assets/img/logo.png';
import strelka from '../../assets/img/strelka.png';
function Header() {
    const { pathname } = useLocation();
    const [active, setActive] = useState(false);
    const [dropDownMenu, setDropDownMenu] = useState(false)
    const [dropDownMenuResponsive, setDropDownMenuResponsive] = useState(false)
    const toggleClass = () => {
        setActive(!active)
    }
    useEffect(() => {
        setActive(false)
    }, [pathname]);
    return (
        <header>
            <div className="header">
                <div className="top-header container">
                    <a className="top_info_box link" href="tel:+374 33778783">
                        <strong className="text">+374 33 778783</strong>
                    </a>
                    <Link to={{ pathname: "https://www.facebook.com/dr.janoyan/" }} target="_blank" ><RiFacebookFill className="icon" /> </Link>
                    <Link to={{ pathname: "https://instagram.com/dr.janoyan?utm_medium=copy_link" }} target="_blank" ><FiInstagram className="icon" /> </Link>
                </div>
            </div>
            <nav className="container">
                <Link className="logo" to="/">
                    <img src={logo} alt="" />
                </Link>
                <div className="mobile_btn" onClick={() => {
                    toggleClass()
                    setDropDownMenuResponsive(false)
                }}>
                    <GiHamburgerMenu className="navicon" />
                </div>


                <ul className={`nav_menu ${active ? 'active' : ''} `}>
                    <li>
                        <NavLink
                            to='/'
                            exact
                            activeStyle={{
                                borderBottom: "2px solid #c19ee0",
                            }}
                            className=""
                        >
                            Գլխավոր էջ
                        </NavLink>
                    </li>
                    <li className='dropDownMenuLi'>
                        <div className='serviceAndStrelka'>
                            <NavLink
                                to='/services'
                                exact
                                activeStyle={{
                                    borderBottom: "2px solid #c19ee0",
                                }}
                                className="servicesMenu"
                                onMouseEnter={() => setDropDownMenu(true)}
                            >
                                Ծառայություններ
                            </NavLink>
                            {dropDownMenuResponsive 
                            ?
                            <img src={strelka} alt="" onClick={() => {
                                setDropDownMenuResponsive(!dropDownMenuResponsive)
                                setDropDownMenu(false)
                            }} className='returnStrelka' />

                            :
                            <img src={strelka} alt="" onClick={() => {
                                setDropDownMenuResponsive(!dropDownMenuResponsive)
                                setDropDownMenu(true)
                            }} className='rotateStrelka' />

                            }
                        </div>

                        {dropDownMenu 
                        ?
                        <div className='dropDownItems' onMouseLeave={() => setDropDownMenu(false)}>

                        <NavLink
                            to='/services/plombavorum'
                            exact
                            className="servicesMenu"
                        >
                            Պլոմբավորում
                        </NavLink>
                        <NavLink
                            to='/services/care'
                            exact
                            className="servicesMenu"
                        >
                            Մասնագիտացված խնամք
                        </NavLink>
                        <NavLink
                            to='/services/help'
                            exact
                            className="servicesMenu"
                        >
                            Լնդերի բուժում
                        </NavLink>
                        <NavLink
                            to='/services/teeth-helping'
                            exact
                            className="servicesMenu"
                        >
                            Արմատախողովակների բուժում
                        </NavLink>
                        <NavLink
                            to='/services/whitening'
                            exact
                            className="servicesMenu"
                        >
                            Ատամների սպիտակեցում
                        </NavLink>
                    </div>

                        :
                        null
                        }

                    </li>
                    <li>
                        <NavLink
                            to='/about'
                            exact
                            activeStyle={{
                                borderBottom: "2px solid #c19ee0",
                            }}
                            className=""
                        >
                            Մեր մասին
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to='/blog'
                            exact
                            activeStyle={{
                                borderBottom: "2px solid #c19ee0",
                            }}
                            className=""
                        >
                            Բլոգ
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to='/contact'
                            exact
                            activeStyle={{
                                borderBottom: "2px solid #c19ee0",
                            }}
                            className=""
                        >
                            Կապ
                        </NavLink>
                    </li>
                </ul>
            </nav>
        </header>
    )
}

export default Header
