import React, { useEffect } from 'react';
import './Blog.scss';
import { BsCalendar2Week } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { Context } from '../../../index';
import {sanitize} from 'dompurify'

function BlogPage(props) {
    const {store} = useContext(Context)
    const blogData = store.getState().blog
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [props.location.state]);
    return (
        <>
            <div className="blogPage">
                <div className="container blogPage__cont">
                    <div className="blogPage__main">
                        {blogData.filter(item => item.id === props.location.state.id).map(item => (
                            <div key={item.id} className="blogPage__post">
                                <div className="blogPage-img">
                                    <img src={item.img} alt="Blog-Img" />
                                </div>
                                <div className="blogPage__center">

                                    <h2>{item.title}</h2>

                                    <span><BsCalendar2Week /> {item.date}</span>

                                   <p dangerouslySetInnerHTML={{__html: sanitize(item.text.replace(/(?:\r\n|\r|\n)/g, '<br>')) }}>

                                   </p>
                                </div>
                            </div>
                        ))
                        }
                    </div>
                    <div className="blogPage__sidebar">
                        <h3>Վերջին նյութերը</h3>
                        {blogData.slice(0, 4).map(item => (
                            <div>
                                <Link to={{
                                    pathname: `/blog/${item.id}`,
                                    state: {
                                        id: item.id,
                                    }
                                }} className="sidebar_post">
                                    <div className="post_img"><img src={item.img} alt="Blog-Img" /></div>
                                    <div className="post_title">
                                        <h4> {item.title} </h4>
                                        <p>{`${item.textPart.slice(0, 35)}...`}</p>
                                    </div>

                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <footer>
                <div className="footer2">
                    © All rights reserved
                </div>
            </footer>
        </>
    )
}

export default BlogPage
