import './ServiceFooter.css'

const ServiceFooter = () => {
    return (
        <div className='serviceFooter'>
            <h1>ԻՆՉՊԵ՞Ս ԿԱՐՈՂ ԵՔ ԳՐԱՆՑՎԵԼ</h1>
            <span>
                Մենք ունենք ճկուն աշխատանքային գրաֆիկ, բարձրակարգ ծառայություններ և դրսևորում ենք հետևողականություն մեր հաճախորդների նկատմամբ։ <br /> <br />

                Նախապես գրանցվելու համար զանգահարեք <b>+374 33 77-87-83</b> հեռախոսահամարով։ <br />
            </span>
        </div>
    )
}


export default ServiceFooter