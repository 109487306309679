import service1 from '../assets/img/dental-care (3)1.png'
import service2 from '../assets/img/dental-care (5)1.png'
import service3 from '../assets/img/tooth (2)1.png'
import service4 from '../assets/img/tooth1.png'
import service5 from '../assets/img/dental-care (4)1.png'
import Blog1 from '../assets/img/blog1.jpg';
import Blog2 from '../assets/img/blog2.jpg';
import Blog3 from '../assets/img/blog3.jpg';
import Blog4 from '../assets/img/blog4.jpg';
import Blog5 from '../assets/img/blog5.jpg';
import Blog6 from '../assets/img/blog6.jpg';

export const servicesData = [
    {
        id: "sd001",
        img: service3,
        name: "Ատամնաքարերի հեռացում",
        desc: [
            "Ենթալնդային և վերլնդային ատամնաքարերի հեռացում՝ ուլտրաձայնային սքեյլինգ",
            "Ատամնափառի հեռացում՝ Air-flow",
            "Փայլեցում",
        ],
        path:'care'
    },
    {
        id: "sd002",
        img: service5,
        name: "Ատամների սպիտակեցում",
        desc: [
            " Լազերային սպիտակեցում` Philips zoom 4",
            "Լուսային սպիտակեցում",
            "Քիմիական սպիտակեցում"
        ],
        path:'whitening'
    },
    {
        id: "sd003",
        img: service1,
        name: "Պլոմբավորում",
        desc: [
            "Ատամի ախտահարված հյուսվածքի հեռացում",
            "Ատամի պսակի բացակայող հատվածի վերականգնում",
            "Բերանի խոռոչի էսթետիկ տեսքի բարելավում(դիմային ատամների կոմպոզիտային պլոմբանյութով վերականգնում"
        ],
        path:'plombavorum'
    },
    {
        id: "sd004",
        img: service2,
        name: "Արմատախողովակների բուժում",
        desc: [
            "Պուլպիտի բուժում(ատամի կակղանի՝ներվի բորբոքում)",
            "Պերիօդբնտիտի բուժում (շուրջարմատային հյուսվածքի բորբոքում",
            "Արմատախողովակների թերլիցքի ապալիցքավորում և բուժում"
        ],
        path:'teeth-helping'
    },
    {
        id: "sd005",
        img: service4,
        name: "Պարօդոնտի բուժում՝ լնդային խնդիրների բուժում",
        desc: [
            "Գինգիվիտի բուժում՝ լնդաբորբ",
            "Պարօդոնտիտի բուժում՝ մարգինալ պերիօդոնտիտ",
            "Պարօդոնտոզի բուժում՝ լնդային դիստրոֆիկ ախտահարման բուժում"
        ],
        path:'help'
    }
]

export const faqs = [
    {
        question: "Կարող եմ արդյո՞ք մասնագիտական խորհուրդ ստանալ ատամնաբուժական ծառայությունների մասով։",
        answer: "Իհարկե,մեր ծառայությունների իմաստը կայանում է նաև որակյալ խորհրդատվությունը։"
    },
    {
        question: "Կարո՞ղ եմ նախապես գրանցել հանդիպումը։",
        answer: "Այո, նախապես գրանցվելու համար զանգահարեք +374 33 77-87-83 հեռախոսահամարով։"
    },
    {
        question: "Ատամների սպիտակեցում իրականացնում ե՞ք։ Եթե՝ այո, ապա ի՞նչ տարբերակով։",
        answer: "Այո, մանրամասների համար կապ հաստատեք կոնտակտային հեռախոսահամարով՝ +374 33 77-87-83։"
    },
    {
        question: "Ատամնաքարերի խնդիր ունեմ համալիր լուծում ունի՞ դա։",
        answer: "Ատամնաքարերն ամենահաճախադեպ խնդիրներից են, այո, մենք  առաջարկում ենք լուծումներ։"
    },
    {
        question: "Ատամների հեռացում իրականացնում ե՞ք։",
        answer: "Եթե հեռացումից բացի այլ տարբերակ չի լինում, հեռացնում ենք։"
    },
    {
        question: "Ձեր հասցեն կասեք, ինչպե՞ս մոտենալ։",
        answer: "Մեր հասցեն՝ ք․ Երևան, Մարշալ Բաղրամյան պող. 68/26,  հեռախոսահամար +374 33 77-87-83։"
    },
    {
        question: "Հայաստանից դուրս գտնվելու պարագայում կարո՞ղ եք նախնական խորհրդատվություն իրականացնել, որ կարողանամ ժամկետները պատկերացնել։",
        answer: "Այո, գործում է նաև էլեկտրոնային հասցե՝ info@janoyandental.am, կարող եք Ձեզ հարմար ժամանակ կապ հաստատել և հայտնել Ձեր խնդրի մասին։"
    }
];

export const homeAboutText = [
    "Janoyan Dental-ն իր ամենաքմահաճ հաճախորդներին է ներկայացնում մի շարք ատամանաբուժական ծառայություններ։",

    "Մենք հասցրել ենք վայելել Ձեր վստահությունը՝ հիմնված բարձր գոհունակության և ծառայությունների մատուցման շարունակականության վրա։",

    "Խոսքից գործ անցնելու ռազմավարությունը ուղի է հարթել հիվանդների շրջանում բացատրական աշխատանքների և պահանջմունքների բավարարման ու մասնագիտական համադրման ճանապարհով։",

    "Մենք ունենք ճկուն աշխատանքային գրաֆիկ, բարձրակարգ ծառայություններ և դրսևորում ենք հետևողականություն մեր հաճախորդների նկատմամբ։",

    "Վստահե՛ք Ձեր կատարյալ ժպիտը մեզ։",
]

export const blogData = [
    {
        id: "bp001",
        cont: {
            img: Blog6,
            title: 'Լնդային խնդիրների բուժում։',
            date: '2022-01-25',
            textPart: "Լնդերի հիվանդության բուժման բազմաթիվ տարբերակներ կան, ուստի ոչ ոք չպետք է տառապի դրա հետևանքներից...",
        },
        text: [
            "Մարդկանց մեծամասնությունը մտածում է կարիեսի և ատամների քայքայման մասին, երբ խոսքը վերաբերում է բերանի խոռոչի առողջությանը, բայց լնդերի հիվանդությունը իրականում շատ ավելի մեծ խնդիր է: Լնդերի հիվանդությունը մեծահասակների մոտ ատամների կորստի հիմնական պատճառն է, և եթե չբուժվի, կարող է նաև հանգեցնել առողջական այլ խնդիրների: Բարեբախտաբար, լնդերի հիվանդության բուժման բազմաթիվ տարբերակներ կան, ուստի ոչ ոք չպետք է տառապի դրա հետևանքներից:",
            "Նախ պետք է հասկանալ՝ ինչն է պատճառը լնդային խնդիրների։ Հիմնական պատճառներից է՝",
            ". Թերի անհատական հիգիենան, որը պայմանավորված է ոչ ամենօրյա մաքրումով, ոչ ճիշտ խոզանակի, ատամնամածուկի, ողողման հեղուկի ընտրությամբ, ատամնաթելի չօգտագործմամբ, իսկ ատամնափառը  լավ միջավայր է ստեղծում բակտերիաների զարգացման համար։",
            ". Ատամնաքարերի առկայությունը.",
            "Բակտերիաների հետևանքով ատամնափառը կարծրանում է՝ առաջացնելով ատամնաքար, սկսվում լնդային բորբոքային պրոցես, ըստ այդմ՝ լնդերի տրավմա, իջեցում, որի հետևանքով հասանելի են դառնում ատամի ավելի խորը հյուսվածքները՝ ստեղծելով լնդային գրպանիկ։",
            ". Այլ պատճառներից են ոչ ճիշտ տեղադրված պրոթեզներ, կամուրջներ, պլոմբաներ, ծխելը, էնդոկրին համակարգի և ստամոքսաղիքային խանգարումը, իմունիտետի անկումը, սթրեսային վիճակը և այլ ֆակտորներ։",
            "Ախտանիշները",
            "Նախնական ախտանիշներից է լնդերի այտուցը, հիպերեմիկ վիճակը,հետագայում ատամները լվանալուց արյան հետքերը, գերզգայունությունը, ատամների վզիկների մերկացումը, տհաճ հոտի և համի առկայությունը։",
            "Բուժման մեջ առաջնայինը ստոմատոլոգի մոտ հաճախելն է և բուժման պլան կազմելը։ Պետք է պացիենտը կատարի ճիշտ անհատական հիգիենա իր հապատասխան պարագաներով,այնուհետև   մասնագիտացված հիգիենա՝ ատամնափառի և ատամնաքարի հեռացում,քսուկների կիրառում և անտիբիոտիկների ընդունում, ողողումների նշանակում։ Իսկ ավելի խորացված և բաց թողնված վիճակներում ՝վիրաբուժական միջամտություն։",
        ]

    },
    {
        id: "bp002",
        cont: {
            img: Blog5,
            title: 'Ատամների խնամքը հղիության ընթացքում։',
            date: '2022-01-18',
            textPart: "Ի՞նչ է նշանակում ատամների առողջություն և ինչպե՞ս է այն ազդում հղիության վրա...",
        },
        text: [
            "Ատամների առողջությունը ձեր բերանի, ատամների և լնդերի առողջությունն է: Եթե դուք հղի եք, դա ձեր նախածննդաբերական խնամքի կարևոր մասն է հանդիսանում (բժշկական օգնություն, որը դուք ստանում եք հղիության ընթացքում):",
            "Հղի լինելը կարող է մեծացնել բերանի խոռոչի առողջության հետ կապված խնդիրների ռիսկը, և դրանք էլ իրենց հերթին կարող են ազդել ձեր հղիության վրա: Օրինակ՝ որոշ ուսումնասիրություններ ցույց են տալիս լնդերի հիվանդության և վաղաժամ ծննդաբերության միջև եղած կապը:",
            "Հղիության ընթացքում բերանի, ատամների և լնդերի խնամքը կարող է օգնել ձեզ ունենալ առողջ հղիություն և առողջ երեխա:",
            "Ինչպե՞ս է հղիությունն ազդում ձեր ատամների առողջության վրա",
            "Հղիության ընթացքում ձեր մարմնի փոփոխությունները կարող են ազդել ատամների և լնդերի վրա.",
            "Օրինակ՝",
            ". Հղիության ընթացքում ձեր մարմնում որոշակի հորմոններ, ինչպիսիք են՝ պրոգեստերոնը և էստրոգենը, ավելանում են: Դրանք կարող են մեծացնել բերանի խոռոչի առողջության հետ կապված որոշակի խնդիրների վտանգը:",
            ". Ձեր ուտելու սովորությունները կարող են փոխվել: Հղիության ընթացքում դուք կարող եք ավելի շատ ուտել որոշակի մթերքներ, քան՝ մինչ հղիությունը: Ձեր կերած սննդի տեսակները կարող են ազդել ձեր ատամների առողջության վրա:",
            ". Հղիանալուց հետո կարող եք ավելի քիչ խոզանակել և թելով մաքրել ձեր ատամները: Սա կարող է պայմանավորված լինել նրանով, որ ձեր լնդերը նուրբ են կամ դուք սովորականից ավելի դյուրագրգիռ եք: Որոշ կանանց մոտ խոզանակը և թելը կարող են առաջացնել սրտխառնոց:",
            "Այս փոփոխությունները կարող են մեծացնել հղիության ընթացքում որոշակի ատամնաբուժական խնդիրների ռիսկը, այդ թվում՝",
            "Կարիես",
            "Սա փոքր, վնասված հատվածն է ձեր ատամի մակերեսի վրա: Հղի լինելը մեծացնում է կարիեսի հավանականությունը: Դուք կարող եք երեխային փոխանցել այն բակտերիաները, որոնք կարիեսային խոռոչներ են առաջացնում հղիության ընթացքում և ծննդաբերությունից հետո: Սա կարող է հետագայում խնդիրներ առաջացնել ձեր երեխայի ատամների համար:",
            "Գինգիվիտ",
            "Գինգիվիտը լնդերի բորբոքում է (կարմրություն և այտուցվածություն): Եթե այն չբուժվի, դա կարող է հանգեցնել լնդերի ավելի լուրջ հիվանդության: Հղիության հորմոնները կարող են մեծացնել գինգիվիտի ռիսկը: Հղի կանանց 60-75 տոկոսը գինգիվիտ ունի: Նշաններն ու ախտանշանները ներառում են՝",
            ". Կարմրություն և այտուցվածություն",
            ". Լնդերի նրբություն",
            ". Լնդերի արյունահոսություն, նույնիսկ երբ դուք նրբորեն մաքրում եք ատամները",
            ". Շողացող լնդեր",
            "Թույլ ատամներ",
            "Հղիության ընթացքում պրոգեստերոն և էստրոգեն հորմոնների բարձր մակարդակը կարող է ժամանակավորապես թուլացնել այն հյուսվածքներն ու ոսկորները, որոնք ատամները պահում են տեղում: Սա կարող է ձեր ատամները թուլացնել:",
            "Պարոդոնտալ հիվանդություն",
            "Եթե գինգիվիտը չբուժվի, այն կարող է հանգեցնել պարոդոնտալ հիվանդության: Սա հանգեցնում է լնդերի լուրջ վարակի և ատամները պահող ոսկորների հետ կապված խնդիրների: Ձեր ատամները կարող են թուլանալ, և դրանք պետք է հեռացվեն: Պարոդոնտիտը կարող է հանգեցնել բակտերեմիայի (արյան մեջ բակտերիաներ): Սա լուրջ վիճակ է, որը անհապաղ բուժման կարիք ունի: Ծխելը լնդերի ծանր հիվանդության պատճառ է հանդիսանում։",
            "Ինչպե՞ս են բուժվում ատամնաբուժական խնդիրները հղիության ընթացքում",
            "Եթե ​​դուք ունեք ատամնաբուժական խնդիր, որը բուժման կարիք ունի, համոզվեք, որ ձեր ատամնաբույժը գիտի, որ դուք հղի եք: Կախված ձեր վիճակից, դուք կարող եք սպասել բուժմանը ձեր երեխայի ծնվելուց հետո: Հղիության ընթացքում անվտանգ բուժումները ներառում են՝",
            ". Դեղորայք ինչպիսիք են ցավազրկողներ և հակաբիոտիկներ՝ վարակները բուժելու համար: Ձեր ատամնաբույժը կարող է ձեզ դեղամիջոց տալ, որն անվտանգ է ձեզ և ձեր երեխայի համար հղիության ընթացքում:",
            ". Տեղային անզգայացում. Անզգայացումը դեղամիջոց է, որը նվազեցնում կամ կանխում է ցավը: Տեղական անզգայացումն օգտագործվում է մարմնի որոշակի հատվածում: Այս դեղը անվտանգ է հղիության ընթացքում օգտագործելու համար:",
            "Դուք կարող եք ատամնաբուժական ծառայություններից օգտվել հղիության ժամանակ: Եթե ​​դա ընտրովի բուժում է (բուժում, որը ձեզ անհապաղ պետք չէ և անհրաժեշտ չէ ձեր կամ ձեր երեխայի առողջությունը պաշտպանելու համար), փորձեք այն նշանակել հղիության երկրորդ եռամսյակում:",
            "Ատամների առողջությունը ձեր ընդհանուր առողջության կարևոր մասն է: Սա նաև ձեր հղիության (նախածննդաբերական) խնամքի կարևոր մասն է:",
        ]
    },
    {
        id: "bp003",
        cont: {
            img: Blog1,
            title: 'Կարիեսի կանխարգելման հիմնական ուղղությունները',
            date: '2021-07-20',
            textPart: "Կարիեսի առաջացման հիմնական պատճառը միկրոօրգանիզմների զարգացումն է բերանի խոռոչում, սակայն առավել քան ցանկալի է չունենալ կարիես,քան պայքարել դրա դեմ...",
        },
        text: [
            "Կարիեսի առաջացման հիմնական պատճառը միկրոօրգանիզմների զարգացումն է բերանի խոռոչում, սակայն առավել քան ցանկալի է չունենալ կարիես,քան պայքարել դրա դեմ։ Բոլորին է հայտնի, որ բերանի խոռոչում առկա են հազարավոր միկրոբակտերիաներ, մինչդեռ սնվելուց  2-4  ժամ հետո  ատամների չլվանալու դեպքում դրանց քանակը կարող է հասնել միլիոնների։ Բնականաբար միկրոօրգանիզմներից ոչ բոլորն են վնասակար, նրանք, որոնք պահպանում են բերանի խոռոչի բնական միկրոֆլորան օգտակար են, մինչդեռ միկրոօրգանիզմների զգալի մասը մեծ վնաս են հասցնում էմալին։ Մեր խնդիրն է նվազեցնել այն գործոնները, որոնց միջնորդությամբ  միկրոբակտերիաները կարող են կարիես առաջացնել։ Եթե խոչընդոտենք բակտերիաների ակտիվ աճին,ապա կունենանք անհամեմատելի դրական արդյունք։ Մեր խելացի օրգանիզմը բակտերիաների առկայության պայմաններում ստեղծում է յուրահատուկ թթվային միջավայր, որպեսզի ոչնչացնի բակտերիաները, մինչդեռ այդ թթվային միջավայրը միևնույն ժամանակ նաև քայքայում է ատամի արտաքին շերտը։",
            "Չբացառելով գենետիկ հիվանդությունները, այդուհանդերձ, մենք պարտավոր ենք խնամել մեր ատամները։ Նման դեպքերում կարիեսը կարող է զարգանալ վաղ մանկական հասակում, երբ  նյութափոխանակության  հետևանքով ի հայտ է գալիս էմալի բարակեցում։ Թքարտադրության  գործընթացի խախտման հետևանքով ատամի արտաքին մակերեսը բնական ճանապարհով չի մաքրվում, նշվածը կարող է ուղեկցվել նաև թքի բաղադրության փոփոխությամբ։, որն էլ իր հերթին հանգեցնում է նյութափոխանակության խնդիրների։",
            "Ի հավելումն նշված իրավիճակների, պետք է հաշվի առնել նաև այն հանգամանքը, որ որոշ դեպքերում առկա են լինում նաև ատամների բնական դեֆեկտներ և խիստ մոտ գտնվող ատամների պարագայում հիգիենայի ապահովում առավել բարդ գործընթաց է։ Անհարթ մակերեսը, շրջապատի օդի աղտոտվածությունը, թունավոր միջավայրում աշխատելը բոլորը հանգեցնում են էմալի հետ կապված խնդիրների, մինչդեռ մենք նվազագույնը կարող ենք կանոնակարգել մեր սնունդը։ Չորացված մրգերը, մեղրը անհրաժեշտ է օգտագործել որպես քաղցրավենիքի փոխարինիչ, օգտագործեք կոշտ մթերքներ՝ գազար, խնձոր, ինչպես նաև թարմ կանաչիներ, միս և ընդեղեն։ Կոշտ մթերքի օգտագործումը նպաստում է լնդերում արյան շրջանառության լավարկմանը, ինչն էլ առողջարար նշանակություն ունի բերանի խոռոչի առողջացման գործում։ Ատամի կոշտ հյուսվածքները սնվում են հանքանյութերով,պանրի, անյուղ ձկան առկայությունը մեր սննդակարգում ևս անչափ օգտակար է։",
            "Ի թիվս նշյալ գործոնների պակաս կարևոր չեն նաև այն հանգամանքները, որոնք վերաբերում են սննդամթերքի տաք և սառը վիճակներին։ Էմալը կարող է ճաքեր ունենալ սառը և տաք սննդի անկանոն հերթագայության հետևանքով,իսկ այդ ճեղքերում կարող են  կուտակվել միկրոբակտերիաներ, և ապա բազմանալ։",
            "Կարիեսի ամենատարածված պատճառներից թվում իր տեղը չի զիջում բերանի խոռոչի հիգիենան։ Անհրաժեշտ է ատամները լվանալ նվազագույնը 2 անգամ օրվա ընթացքում և անել դա ճիշտ եղանակով՝  լնդերից դեպի ատամի գլխիկ։ Մաքրման գործընթացը չպետք է 3 րոպեից պակաս լինի, իսկ լվանալիս անհրաժեշտ է  մաքրել նաև լեզուն և այտերի ներքին պատերը։",
            "Պրոֆիլակտիկ այցելություններ",
            "Կարիեսի կանխարգելման ամենահաջող եղանակներից մեկը ատամնաբույժին կանոնավոր այցելություններն են։ Որևէ խնդիր չունենալու դեպքում անգամ ատամնաբույժին տարին 2 անգամ այցելելը պարտադիր պայման է։ Ինչու՞ է կարևոր  այցելել ատամնաբույժին։Կարիեսը անզեն աչքով նկատելի է դառնում այն ժամանակ, երբ այն արդեն բավական զարգացել է կամ ի հայտ է եկել ատամների գերզգայունություն։ Պաթոլոգիկ երևույթները երբեմն այնքան էլ նկատելի չեն, քանի որ հավանական է, որ խնդիրները կապված են այնպիսի ատամների հետ, որոնք տեսանելի դաշտում չեն, կամ այնպիսի հատվածների հետ, որոնք դժվար հասանելի են ատամի խոզանակի համար։ Օրինակ՝ կտրիչների ներքին պատերի կարիեսը  գրեթե տեսանելի չէ։",
            "Հարկավոր է չսպասել պլանային  հետազոտությունների, այլ դիմել ատամնաբույժի  հետևյալ խնդիրներից ցանկացածի ի հայտ գալու պարագայում՝",
            "Մեկ կամ մի քանի ատամների գերզգայունություն՝ տաք կամ սառը սննդից կամ հեղուկից, կամ այլ պատճառով,",
            "Ցանկացած ինտենսիվության ատամնացավ,",
            "Լնդերի արյունահոսություն,",
            "Էմալի գույնի փոփոխություններ,",
            "Վստահե՛ք Ձեր կատարյալ ժպիտը մեզ։",
            "Ցանկացած այլ խնդրի դեպքում։",
            "Ալկոհոլի և ծխախոտի չափաշահումը ևս կարիեսին նպաստող գործոններ են։ Հարկավոր է նաև տարվա ընթացքում ընդունել վիտամինային կոմպլեքսներ, որոնք դրականորեն կազդեն ինչպես ընդհանուր օրգանիզմի, այնպես էլ ատամների վրա։"
        ]
    },
    {
        id: "bp004",
        cont: {
            img: Blog2,
            title: 'Ի՞նչը կարող է հանգեցնել ատամնաքարերի առկայությանը',
            date: '2021-07-28',
            textPart: "Ատամնաքարերի խնդրի մասին կարելի է նույնիսկ չկռահել մինչև առողջ ատամները և լնդերը չսկսեն անհանգստացնել։ Մինչև խնդրի բարդացումը ատամնաքարերի հայտնաբերումը հնարավոր է միայն ատամնաբույժի օգնությամբ...",
        },
        text: [
            "Ի՞նչը կարող է հանգեցնել ատամնաքարերի առկայությունը",
            "Ատամնաքարերի խնդրի մասին կարելի է նույնիսկ չկռահել մինչև առողջ ատամները և լնդերը չսկսեն անհանգստացնել։ Մինչև խնդրի բարդացումը ատամնաքարերի հայտնաբերումը հնարավոր է միայն ատամնաբույժի օգնությամբ։",
            "Ինչու՞ է ի հայտ գալիս ատամնաքարը և ինչո՞վ է այն վտանգավոր։",
            "Ատամները առավոտյան և երեկոյան լվանալու մասին հայտնի է բոլորին՝ անգամ երեխաներին։ Սնունդ ընդունելուց 2 ժամ անց ատամի արտաքին մակերևույթին հայտնված ուտելիքի մնացորդների հետևանքով ատամի վրա կուտակվում են կալցիումի աղեր, որոնք ժամանակի ընթացքում կոշտանում են, այսինքն՝ ատամնաքարը  միներալիզացված երբեմնի փափուկ նստվածքն է, որի հետ խոզանակով և ատամի մածուկով պայքարելը գրեթե անհավանական է։ Ատամնաքարերից ազատումը ենթադրում է արդեն մասնագիտացված ծառայության մատուցում։ Ատամնաքարերն իրենց հերթին՝ ըստ տեղակայման լինում են ենթալնդային և  վերլնդային։ Վերին շերտի ատամնաքարերը հեշտ նկատելի են՝ դրանք դեղնավուն կոշտ զանգվածներ են, որոնք տեղակայված են ատամի և լնդի հատվածում՝ պինդ են, ունեն կավին նմանվող բաղադրություն։ Մարդկանց 70 տոկոսից ավելին իրենց կյանքի տարբեր հատվածներում սկսած 9-15 տարեկան և ավելի բարձր, ունենում են ատամնաքարեր։",
            "Ատամնաքարերը առավել հաճախ հանդիպում են  այն հատվածներում, որոնք դժվարամատչելի են ատամի խոզանակի համար և հետևապես հիգիենան չի իրականանում, այնպես, ինչպես պետք է իրականանար, օրինակ՝ պարադոնտալ գրպանիկներում, իմպլանտացիայի հատվածներում, բրեկետների ամրացման հատվածներում, իմաստնության ատամների հատվածում։",
            "Որո՞նք են ատամնաքարերի առաջացման ախտանշանները՝",
            "Տհաճ հոտ, որը բակտերիաների արագ աճի հետևանքն է,",
            "Լնդերի կարմրություն,",
            "Լնդերի արնահոսութուն,",
            "Որոշ ատամների զգայունություն, որը հետևանք է հյուսվածքների քայքայման,",
            "Ատամի վզիկի «մերկացումը»,",
            "Էմալի գույնի փոփոխությունը՝ ոչ էսթետիկ ու շագանակագույն հետքերի առաջացումը։",
            "Ատամների վրա  կոշտ գոյացությունները միանգամից չեն առաջանում, նախ առաջանում է ատամնափառը, և վերջինիս առաջացումը չկանխելու դեպքում զարգանում է ավելի բարդ գործընթացը, որն  է՝ ատամնաքարերի հեռացումը։ Ինչը նշանակում է, որ բերանի խոռոչի բավարար հիգիենայի դեպքում կարելի է  կանխարգելել ատամնափառի առաջացումը՝ հետևապես նաև ատամնաքարերի առաջացումը։ Ի թիվս նշված պատճառների՝ առկա են նաև ատամնաքարերի առաջացման այլ պատճառներ՝",
            "Ատամի խոզանակի, ատամի մածուկի սխալ ընտրություն,            ",
            "Ոչ բավարար քանակությամբ կոշտ սննդամթերքի օգտագործում, որը մեխանիկական մաքրում է իրականացնում ատամի արտաքին մակերևույթի վրա,",
            "Քաղցրեղենի չարաշահումը,գազավորված ըմպելիքների օգտագործումը, մեծ քանակությամբ ածխաջրեր պարունակող սննդամթերքի օգտագործումը, որը հանգեցնում է թքի բաղադրության փոփոխությանը և հարակից այլ խնդիրների,",
            "Ներքին օրգանների որոշ հիվանդությունները,            ",
            "Ծխելը։",
            "Ատամնաքարերը շատ ավելի լուրջ խնդիր են,քան տգեղ դեղին նստվածքն ատամների վրա, քանի որ դա հենց նույն նստվածքի հետևանքն էր, որն արդեն պնդացել է և կարիք ունի բժշկական միջամտության։ Երկրորդային հետևանքները սպասեցնել չեն տալիս՝            ",
            "Բերանի տհաճ հոտ,",
            "Լնդերում քոր,",
            "Լնդերի արյունահոսություն,",
            "Այլ հետևանքներ։",
            "Իսկ ի՞նչ է լինում, եթե չենք մաքրում ատամնաքարը՝",
            "Պարադոնտալ գրպանիկի առաջացում,",
            "Կարիեսի, գինգիվիտի, պարադոնտիտի առաջացում,",
            "Ատամները սկսում են շարժվել և ապա ընկնել կամ հեռացման ենթակա են դառնում,",
            "Աղեստամոքսային խնդիրներ,բերանի խոռոչում ատամնաքարի առկայությունը ինֆեկցիոն օջախ է և թքի միջոցով կարող է  պատճառ դառնալ այլ հիվանդությունների առաջացման համար,",
            "Սիրտ-անոթային համակարգի խնդիրներ,",
            "Այլ հարակից խնդիրներ։",
            "Ինչպե՞ս ազատվել ատամնաքարերից և ատամնափառից։",
            "Ատամնաքարերի հեռացումը ոչ թե պրոֆիլակտիկ գործողություն  է ,այլ  բուժիչ գործընթաց՝ պայքար բակտերիաների դեմ։Այն իրենից ենթադրում է անձնական և մասնագիտական միջոցառումների համալիր կիրառում։Համացանցում կարելի է գտնել ատամնաքարերի և ատամնափառի հեռացման բազմաթիվ եղանակներ, մինչդեռ  այդ միջոցառումները իրականում կարող են էլ ավելի վատթարացնել ատամի էմալի վիճակը։",
            "Կարելի է արդյո՞ք տնային պայմաններում հեռացնել ատամանքարերը։Պատասխան՝ ոչ, քանի որ կոշտացած մասերը անվնաս հեռացնելու տնային մեթոդներ չկան, սակայն կարելի է տան պայմաններում պարզապես նպաստել նրան, որ դրանք չառաջանան։Ի՞նչպես դա անել։",
            "Ընտրել լավ ատամի խոզանակ և ատամի մածուկ,",
            "Ունենալ ատամի մաքրման լրացուցիչ այլ ատրիբուտներ,",
            "Օգտագործել բերանի խոռոչը ողողելու համար նախատեսված հեղուկներ,",
            "Օգտագործել օրթոդոնտիկ ատամի խոզանակ, եթե նման ցուցում ունեք,",
            "Լեզվի խնամքի համար ունենալ խոզանակ,",
            "Ատամի խոզանակը չի կարող հեռացնել ատամնաքարը, բայց կարող է հեռացնել ատամնափառը։Երբեմն օգտակար է կիրառել հետևյալը՝",
            "Էլեկտրական ատամի խոզանակ,",
            "Ուլտրաձայնային, եթե կրում եք վինիրներ,",
            "Ընտրել ատամի մածուկ, որը պարունակում է օգտակար ֆերմենտներ,ֆտոր։ ",
            "Պետք է ընդունել, որ լուրջ խնդիրների դեպքում նշյալ միջոցառումները կարող են արդյունավետ չլինել, օրինակ,եթե առկա է մասշտաբային պիգմենտացիա։ Նման պարագայում առավել նախընտրելի են ստոմատոլոգիական միջոցառումները՝ ուլտրաձայնային մաքրում, լազերային մաքրում կամ գործիքային այլ եղանակներ։",
        ]
    },
    {
        id: "bp005",
        cont: {
            img: Blog3,
            title: 'Ցիտրուսների օգտակար և վնասակար ազդեցություններն ատամների վրա',
            date: '2021-06-25',
            textPart: "Ատամների առողջությունը կախված է ոչ միայն բերանի խոռոչի հիգիենայից, այլ մի քանի այլ գործոններից ևս, որոնց թվին է պատկանում ճիշտ սնունդը...",
        },
        text: [
            "Ատամների առողջությունը կախված է ոչ միայն բերանի խոռոչի հիգիենայից, այլ մի քանի այլ գործոններից ևս, որոնց թվին է պատկանում ճիշտ սնունդը։ Սննդի կարգավորումն ու հավասարակշռումը՝ ըստ անհրաժեշտ միկրոէլէմենտներով հագեցվածության, պետք է նկատի ունենա նաև, որ օրգանիզմի համար որոշ սննդամթերքի չարաշահումը կարող է հանգեցնել ատամների էմալի քայքայմանը։ Ատամի էմալի վրա բացասական ազդեցություն ունեցող մթերքների ցանկը չի սահմանափակվում  սուրճով, թեյով, կարմիր գինով և գունավոր ալկոհոլային խմիչքներով, առաջին հայացքից միայն օգտակար թվացող ցիտրուսային հյութալի մրգերը ևս կարող են վնաս հասցնել, եթե բավարար ուշադրություն չդարձնել դրանց օգտագործման չափաբաժիններին։",
            "Ցիտրուսային մրգերի՝ ճիշտ սննդառության կատեգորիային պատկանելությունն, իհարկե, միֆ չէ, սակայն պետք է  հաշվի առնել, որ ցիտրուսային մրգերը մեծ քանակությամբ թթուներ են պարունակում,որոնք կարող են քայքայել բերանի խոռոչի բնական միկրոֆլորան։ Ցիտրուսային հյութերը պարունակում են մեծ քանակությամբ վիտամին C, որոնք օրգանիզմի  համար անփոխարինելի են, մինչդեռ դրանց չարաշահման դեպքում կարելի է հրաժեշտ տալ ատամի էմալին։",
            "Ցիտրուսային մրգեր օգտագործելուց հետո խորհուրդ է տրվում 20-30 րոպե ատամները չլվանալ,այլ պարզապես  ողողել ջրով՝ այդպիսով նեյրտալիզացնելով թթվային միջավայրը բերանի խոռոչում։ Կա ևս մեկ կարևոր հանգամանք․ ցիտրուսային հյութերը նախընտրելի է խմել ձողիկով։ Վերջինը վերաբերում է նաև այն բոլոր հյութերին, որոնք թթուներ են պարունակում։",
            "Ի թիվս օգտակար հատկությունների՝ կարող ենք նշել հետևյալը՝  նարնջի մեջ պարունակող վիտամինները ապահովում են լնդերում կոլլագենային ցանցի առաջացմանը և խոչընդոտում են լնդերի հետ կապված մի շարք հիվանդություններ, որոնք կարող են առաջացնել ինչպես կարիես, այնպես էլ լնդային հիվանդություններ։            ",
            "Գրավիչ ժպիտ ունենալը բոլորի ցանկութունն է։ Այն առողջության, գրավչության,նաև նորաձև լինելու լավագույն գրավականներից է։ Մինչ ստոմատոլոգներն առաջարկում եմ ատամների սպիտակեցնելու բազում միջոցներ՝ մեր տատիկները գտել են ավելի մատչելի լուծում՝  ցիտրուսային մրգերի հաճախակի օգտագործումը, բայց եկեք խոստովանենք, որ ժամանակները փոխվել են և  հաշվի առնելով հնարավոր բացասական հետևանքները, ցանկալի է զերծ մնալ տնայնաբուժությունից։            ",
            "Օրգանական թթուներով հարուստ թուրինջը համալրում է ամենահամեղ և ամենաօգտակար ցիտրուսների շարքը,սակայն պետք է ի նկատի ունենալ, որ թե՛ նարնջի,կիտրոն և թե՛ թուրինջի դեպքում 1,5  հատ միրգը լիովին լրացնում է վիտամին С-ի պակասը։ Վիտամիններ А և Е  հակաօքսիդանտ են և նպաստում են բերանի խոռոչի բնական միկրոֆլորայի պահպանմանը։ Հետաքրքրական է այն հանգամանքը, որ  նարնջի ֆոսֆորը և կալցիումը  պաշտպանում են ատամի էմալը և բարելավում լնդերի վիճակը։ Բոլոր տեսակի ցիտրուսները պայքարում են ճարպային սննդամթերքի դեմ՝ սկսած բերանի խոռոչից մինչ ամբողջ օրգանիզմում տարածվելը։ Ծխախոտ օգտագործող անձինք իրենց  ընդհանուր առողջության և ատամների  առողջության պահպանման համար պետք է օգտագործեն ցիտրուսներ՝ չափավոր քանակով։            ",
            "Մագնեզիում, ֆոսֆոր, երկաթ, նատրիում, կալցիում և այլ վիտամիններ պարունակող մանդարինները  բնական հակասեպտիկներ են։ Լայմը բարենպաստ է ինչպես բերանի խոռոչում բակտերիաների ոչնչացման համար,այնպես էլ նյարդային համակարգի կարգավորման համար,նշվածը ենթադրում է, որ ատամների առողջությունը ո՛չ միայն արտաքին առողջությունն է,այլև՝ ավելի խորքային։",
            "Ցիտրուսները պայքարում են բակտերիաների դեմ,ամրացնում են լնդերը,կանխում են կարիեսը,նպաստում են թարմ շնչառությանը։ Տնային պայմաններում,թերևս, ատամները սպիտակեցնել ցանկացողները պետք է քաջատեղյակ լինեն հետևյալ հակացուցումների մասին՝",
            "Ատամի էմալի ճաքեր,",
            "Լնդային հիվանդություններ,",
            "Ատամների գերզգայունություն,",
            "Բարակ էմալ,",
            "Շրթունքների հերպեսի առկայություն,",
            "Այլ...",
            "Ցիտրուսային մրգերը հրաշալիորեն պայքարում են ատամնափառի դեմ, ուստի վերջիններիս չափավոր օգտագործումը նպաստավոր միջավայր է ատամների համար, իսկ չարաշահումը հանգեցնում է էմալի վնասմանը։",
        ]
    },
    {
        id: "bp006",
        cont: {
            img: Blog4,
            title: 'Ինչպե՞ս ճիշտ խնամել ատամները։',
            date: '2021-05-03',
            textPart: "Ատամները պետք է լվանալ նվազագույնը օրը 2 անգամ»․ սա արտահայտություն է , որն ամենից շատ ենք լսում ատամները խնամելու մասին...",
        },
        text: [
            "«Ատամները պետք է լվանալ նվազագույնը օրը 2 անգամ»․ սա արտահայտություն է , որն ամենից շատ ենք լսում ատամները խնամելու մասին, բայց չէ՞ որ ատամները օրը 2 անգամ լվանալը չի լուծում ատամի հետ կապված բոլոր խնդիրները, այլ պարզապես կանխում է այլ խնդիրների առաջացումը և վերջիվերջո լուծում է բերանի խոռոչի հիգիենայի հարցը։ Մինչդեռ բերանի խոռոչի հիգիենայի հարցերը ընդգրկում են միջոցառումների և պրոցեդուրաների մի ամբողջ զինանոց։           ",
            "Ինչպե՞ս ընտրել ճիշտ խոզանակ։",
            "Ատամի խոզանակի ընտրությունը բավականին բարդ գործընթաց է։ Նախընտրելի է, որ խոզանակի լինի կոշտ մազիկներով, սակայն ոչ այնքան կոշտ, որ վնասի ատամի էմալը։ Դժբախտաբար վնասված էմալը գրեթե չի վերականգնվում, հետևապես ատամի խոզանակի ընտրությունը և վերջինիս ճիշտ օգտագործումը Ձեզ մեկ քայլ հետ կպահեն բերանի խոռոչի խնդիրներից։ Խոզանակների ներկայիս բազմազանությունը հնարավորություն է ընձեռում  անցում կատարել ձեռքի խոզանակից դեպի էլեկտրական խոզանակը, եթե առաջինի պարագայում մենք պետք է գիտակցաբար շարժենք խոզանակը լնդերից դեպի ատամի գլխիկը, ապա երկրորդ դեպքում համակարգը ինքն արդեն մշակված է՝ անհրաժեշտ է պարզապես խոզանակը մոտեցնել ատամներին, մնացածը խոզանակի գործն է։",
            "Բայց․․․կա մի բայց, էլեկտրական ատամի խոզանակը կարող է էլ ավելի բարդացնել հիվանդի կարգավիճակը, եթե նա արդեն իսկ ունի որոշակի խնդիրներ բերանի խոռոչում՝ քայքայված էմալ,արնահոսող  լնդեր, կարիես և այլն։ Նման դեպքերում խոզանակի օգտագործումից առաջ անհրաժեշտ է վերացնել նշյալ խնդիրները։ Երեխաների դեպքում էլեկտրական, մանկական գունագեղ տարբերակները, որոնք աշխատում են յուրահատուկ «շարժիչով» շատ ավելի ոգևորիչ են և խթանում են ատամների լվացման գործընթացը կանոնավոր դարձնելուն։ Խորհուրդ է տրվում երեխաների դեպքում էլեկտրական և ձեռքի խոզանակները օգտագործել հերթագայությամբ։ Նախ նա սովորում է, թե ինչպես և ինչ ուղղությամբ շարժել խոզանակը, ապա դա անում է ձեռքի խոզանակով։           ",
            "Ատամի մածուկի ընտրությունը",
            "Արդյո՞ք միևնույնն է ատամի խոզանակի ընտրությունը։Պե՞տք է արդյոք ատամի խոզանակի հարցում կողմնորոշվել արժեքով։ Վատ ատամի մածուկը, որը նաև մատչելի է, լավագույն դեպքում կարող է գումարի կորուստ լինել, իսկ վատագույն դեպում՝  ատամի էմալի վնասման պատճառ դառնալ։ Չի բացառվում նաև,որ  բազմազանության համար գրեթե իրարից բաղադրությամբ չտարբերվող ատամի մածուկները հայտնվեն շուկայում  տարբեր փաթեթավորմամբ և արժեքով։",
            "Ատամի մածուկի ընտրության հարցում հարկավոր է խորհրդակցել ատամնաբույժի հետ։ Վերջինս ավելի ճիշտ կկողմնորոշվի ատամի մածուկը ընտրության հարցում, քանի որ ավելի լավ է պատկերացնում բերանի խոռոչի՝ Ձեր խնդիրների մասին։ Օրինակ, եթե այցելուն ունի կարիեսի առաջացման հակում,ապա ցանկալի է, որ օգտագործի ֆտոր պարունակող ատամի մածուկ, զգայուն էմալի դեպքում ընտրությունը կլինի տարբեր։",
            "Խանութներում և դեղատներում վաճառվող ոչ բոլոր  մածուկներն են  այս կամ այն ատամնաբուժական խնդրի լուծում հանդիսանում։Նորագույն տեխնոլոգիաների կիրառմամբ ստեղծված ատամի մածուկները հանդես են գալիս ամենաընդարձակ բազմազանությամբ։ Այն վայրերում, որտեղ բնակչությունը օգտվում է ֆտորով հագեցած ջրից՝ հակացուցված է ֆտորով ատամի մածուկի օգտագործումը։ Նման դեպքերում անհրաժեշտ է խուսափել նաև նատրիումի  լաուրիլսուլֆատ, տրիկլոզան,կալցիումի կարբոնատ պարունակող ատամի մածուկներից։",
            "Նույնիսկ կանոնավոր լվացումների դեպքում օգտվեք ֆլոսսից և բերանի ողողման համար նախատեսված նյութերից։ Առնվազն տարին մեկ անգամ խորհուրդ է տրվում իրականացնել ատամների  մասնագիտական մաքրում, իսկ խնդիրների բացակայության դեպքումատամնաբույժին այցելությունները պետք է լինեն ոչ պակաս, քան տարին 2 անգամ։            ",
            "Անձինք, ովքեր ռիսկային գոտում են՝ դեռահասները, հղի կանայք,ատամների գերզգայունություն ունեցողները, բացի  մաքրումը պետք է իրականացնեն նաև ատամների էմալի  ռեմիներալիզացիայի պրոցեդուրան։ Բացի բուժական պրոցեդուրաները՝  հաճախ հիվանդները դիմում եմ կոստմեիկ միջամտությունների համար՝ ատամների սպիտակեցում, ատամնաշարի շտկում և այլն։ի լրումն նշվածի՝ պետք է կողմնորոշվել նաև ներկանյութեր պարունակող սննդամթերքի հարցում։",
            "Ատամների խնամքը այն նվազագույնն է, որ կարող եք անել Ձեր  անհոգ ժպիտը պահպանելու հարցում։",
        ]
    },

];

