import React from 'react';
import './CertificatesSlider.scss';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import { Link } from 'react-router-dom';

import pt1 from '../../assets/img/cert1.jpg';
import pt2 from '../../assets/img/cert2.jpg';
import pt3 from '../../assets/img/cert3.jpg';
import pt4 from '../../assets/img/cert4.jpg';
import pt5 from '../../assets/img/cert5.jpg';
import pt6 from '../../assets/img/cert6.jpg';
import pt7 from '../../assets/img/cert7.jpg';


const data = [
    {
        id: 1,
        img: pt1
    },
    {
        id: 2,
        img: pt2
    },
    {
        id: 3,
        img: pt3
    },
    {
        id: 4,
        img: pt4
    },
    {
        id: 5,
        img: pt5
    },
        {
        id: 6,
        img: pt6
    },
    {
        id: 7,
        img: pt7
    },
]

SwiperCore.use([Autoplay]);
function CertificatesSlider() {
    return (
        <section className="section3">
            <div className="sec_title">
                <h6 className="container">Հավաստագրեր</h6>
            </div>
            <div className="slide_part">
                <Swiper
                    spaceBetween={30}
                    slidesPerView={1}
                    loop={true}
                    loopFillGroupWithBlank={true}
                    autoplay={{
                        delay: 3000,
                    }}

                    breakpoints={{
                        "600": {
                            "slidesPerView": 2,
                        },
                        "950": {
                            "slidesPerView": 3,
                        },

                    }}
                >
                    {data.map(slide => (
                        <SwiperSlide
                            key={slide.id}
                        >
                            <Link to="/about">
                                <div className="single-slide">
                                    <img src={slide.img} alt="" />
                                </div>
                            </Link>

                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    )
}

export default CertificatesSlider
