import Blog1 from '../assets/img/blog1.jpg';
import Blog2 from '../assets/img/blog2.jpg';
import Blog3 from '../assets/img/blog3.jpg';
import Blog4 from '../assets/img/blog4.jpg';
import Blog5 from '../assets/img/blog5.jpg';
import Blog6 from '../assets/img/blog6.jpg';


const initialState = [
    
];




const blogReducer = (state = initialState,action) => {
    switch(action.type){
        default:
            return state
    }
}


export default blogReducer