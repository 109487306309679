import './Whitening.css'

const Whitening = (props) => {
    return (
        <div className='whiteningMain'>
            <div className='topPartWhitening'>
                <div className='centerPartWhitening'>
                    <div className='serviceMiniTextFlexWhitening'>
                        <h1 className='serviceTextWhitening'>Արմատախողովակների բուժում </h1>
                        <span className='serviceMiniText'>Ատամների հետ կապված խնդիրները բազմազան են ճիշտ այնքան, որքան բազմազան են դրանք պայմանավորող գործոնները։  Բերանի խոռոչի  նկատմամբ ոչ կայուն խնամքը կարող է հանգեցնել լուրջ խնդիրների, իսկ լուրջ խնդիրները խոչընդոտում են գեղեցիկ ժպիտ ունենալուն։ <br /> <br /> Janoyandental-ի խնդիրն է՝ ամեն ինչ անել այն բանի համար, որ օգնեն  պահպանելու ատամների առողջությունը և գեղեցիկ ժպիտը:</span>
                        <button className='serviceButtonJoinWhitening' onClick={() => {
                                props.history.push('/contact')
                            }}>ՀԵՐԹԱԳՐՈՒՄ</button>
                    </div>
                </div>
            </div>
            <div className='secondSectionWhiteningMain'>
                <div className='secondSectionWhitening'>
                    <div className='h2Flex mt'>
                        <h2>Ի՞ՆՉ Է ԿԱԿՂԱՆԸ</h2>
                    </div>
                    <span>

                        Ատամների հետ կապված խնդիրները բացահայտելու համար նախ հասկանանք, թե ինչպիսի՞ն է ատամի անատոմիան։ <br /> <br />

                        Ատամի տեսանելի հատվածը պսակն է, իսկ ահա անտեսանելի հատվածը՝ արմատը։ <br /> <br />

                        Թերապևտիկ ստոմատոլոգիական միջամտությունները նպաստում են խնդիրների կանխմանը, սակայն չեն բացառում այնպիսի երևույթների ի հայտ գալը, ինչպիսին է պուլպան (կակղանը)։ <br /> <br />

                        Վերջինս բաղկացած է  արյան անոթներից և նյարդերից։ <br />
                    </span>

                </div>
            </div>
            <div className='secondSectionWhiteningMain2'>
                <div className='secondSectionWhitening '>
                    <div className='h2Flex'>
                        <h2>Ե՞ՐԲ ԴԻՄԵԼ ԲԺՇԿԻ</h2>
                    </div>
                    <span>

                        Ձեզ անհրաժեշտ է անհապաղ դիմել ատամնաբույժին խորհրդատվության համար, եթե Ձեզ մոտ ի հայտ է եկել կակղանի բորբոքում:
                    </span>

                </div>
            </div>
            <div className='secondSectionWhiteningMain'>
                <div className='secondSectionWhitening ' >
                    <div className='h2Flex'>
                        <h2>ԿԱԿՂԱՆԻ ԲՈՐԲՈՔՈՒՄԸ</h2>
                    </div>
                    <span>

                        Մարդկանց շրջանում  կակղանի բորբոքումը նույնացվում է ատամի նյարդի բորբոքման հետ։ <br /> <br />

                        Նյարդի բորբոքումը լուրջ ինֆեկցիոն պրոցես է։ <br />  <br />
                        Պրոցեսն իրենից ենթադրում է  վարակված և քայքայված կակղանի հյուսվածքների հեռացում։ <br /> <br />

                        Այսպիսով՝ մեխանիկական և քիմիական մշակում անցած արմատախողովակները լեցման փուլում արդեն համարվում են բուժված։ <br />
                    </span>

                </div>
            </div>
            <div className='secondSectionWhiteningMain2'>
                <div className='secondSectionWhitening ' >
                    <div className='h2Flex'>
                    <h2>ԲՈՒԺՈՒՄԸ ԱՆԶԳԱՅԱՑՄԱՆ ՄԻՋՈՑՈՎ</h2>
                    </div>
                    <span>

                        Իրականում՝ պետք չէ  կակղանը ասոցացնել միայն նյարդային թելերի հետ, այն ունի նաև զարկերակներ, շարակցական հյուսվածքներ։ Այդ է պատճառը, որ կակղանի բուժման գործընթացը ուղեկցվում է տեղային անզգայացմամբ։ Մինչդեռ այն կասկածները, որ խողովակների բուժումը ցավոտ պրոցես է, հիմնականում չի համապատասխանում իրականությանը, քանի որ այն ընդհանուր առմամբ նման է ատամնալիցքի տեղադրմանը։
                    </span>

                </div>
            </div>
            <div className='secondSectionWhiteningMain'>
                <div className='secondSectionWhitening ' >
                    <div className='h2Flex'>
                    <h2>ԱՆՎՃԱՐ ԽՈՐՀՐԴԱՏՎՈՒԹՅՈՒՆ</h2>
                    </div>
                    <span>

                        Կարող եք գրանցվել անվճար խորհրդատվության՝ նախապես հերթագրվելով կենտրոնում: <br /> <br />

                        Ժամանակակից տեխնոլոգիաները օգնում են ախտորոշել հիվանդությունը սկզբնական փուլում, ինչը մեծացնում է արդյունավետ բուժման հնարավորությունն առանց վնաս հասցնելու ատամի հյուսվածքներին: <br /> <br />

                        Սա հնարավոր է դարձնում անցկացնել մանրակրկիտ ախտորոշում և բուժման ընթացքում խուսափել անցանկալի բարդություններից: <br /> <br />

                        Արժե նաև հիշեցնել, որ Janoyandental-ում բոլոր արմատախողովակների բուժումները կատարվում են միայն վերջին սերնդի տեխնոլոգիաների օգտագործմամբ: <br />
                    </span>

                </div>
            </div>
        </div>
    )
}


export default Whitening